import { useQuery } from "react-query";
import { supabase } from "../lib/api";

const getUsers = async () => {
  let { data, error } = await supabase.from("users").select(`*`);

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error("User not found");
  }

  return data;
};

export default function useUsersQuery(params = {}) {
  return useQuery(["users"], () => getUsers(), {
    ...params,
  });
}
