// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import { supabase } from "../lib/api";
import Tree, { TreeNode } from "rc-tree";
import {
  FolderIcon,
  FolderOpenIcon,
  FolderRemoveIcon,
  CollectionIcon,
  QuestionMarkCircleIcon,
  LockClosedIcon,
  LockOpenIcon,
  RefreshIcon,
  ExclamationIcon,
  ExternalLinkIcon,
} from "@heroicons/react/outline";
import { cx, css } from "@emotion/css";

import { useSearch, useNavigate } from "@tanstack/react-location";

import {
  useThrottle,
  useDebouncedValue,
  useCountdown,
  useMapState,
} from "rooks";

import CopyLink from "../lib/CopyLink";

import useAppStore from "../store/app";

// @ts-ignore
import KeyboardEventHandler from "react-keyboard-event-handler";

import useSingleQuery from "../hooks/useSingleQuery";
import useExistsQuery from "../hooks/useExistsQuery";
// import { useBookmarkLinkQuery, useBookmarkLinkMutation } from "../hooks/useBookmarkLink";
import useUserQuery from "../hooks/useUserQuery";
import useBookmarkUpdate from "../hooks/useBookmarkUpdate";
import useRealtime from "../hooks/useRealtime";
import PermissionsHeader from "./PermissionsHeader";

const ViewBookmark = ({ user }) => {
  const [status, setStatus] = useState("view"); // view / edit
  const [viewStatus, setViewStatus] = useState("view"); // view / markdown
  const [editStatus, setEditStatus] = useState("edit"); // edit / preview

  const iframeRef = useRef();

  const [title, setTitle] = useState("");
  const [autosaveNum, setAutosaveNum] = useState(0);
  const [debouncedValue, immediatelyUpdateDebouncedValue] = useDebouncedValue(
    autosaveNum,
    1500
  );
  // const realSaveNum = useThrottle(autosaveNum, 3000); // should use debounce instead?
  const waitingToSave = autosaveNum !== debouncedValue ? true : false; // autosaveNum !== realSaveNum ? true : false;

  // autosave
  useEffect(() => {
    if (status === "edit" && debouncedValue) {
      // console.log("SAVE UPDATED CONTENT", autosaveNum);
      // console.log("autosave", autosaveNum);
      handleSave();
    }
  }, [status, debouncedValue]);
  // console.log("num", autosaveNum, debouncedValue);

  const setCurrentlyViewing = useAppStore((s) => s.setCurrentlyViewing);
  const search = useSearch();
  const navigate = useNavigate();

  const {
    data: bookmark,
    isLoading: isLoadingBookmark,
    isError: isErrorBookmark,
    refetch: refetchBookmark,
  } = useSingleQuery({
    table: "bookmarks",
    id: search.id,
    select: `
      *,
      owner:owner_id (
          email
      )`,
  });

  const {
    data: existsObj,
    isLoading: isLoadingExistsObj,
    isError: isErrorExistsObj,
    refetch: refetchExistsObj,
  } = useExistsQuery({
    table: "bookmarks",
    pk: search.id,
  });

  const onUpdateBookmark = React.useCallback((newBookmark) => {
    // setBookmark(newBookmark)
    refetchBookmark();
  }, []);

  const { presenceState, channel, setPresence } = useRealtime({
    room: search.id,
    user,
    // id: search.id,
    // table: "bookmarks",
    // onUpdate: onUpdateBookmark,
  });
  // console.log("presenceState:", presenceState);
  const presenceIsCurrentlyEditing = isEditing(presenceState);
  // console.log("presenceIsCurrentlyEditing:", presenceIsCurrentlyEditing);

  useEffect(() => {
    if (bookmark) {
      // && status !== "edit") {
      setTitle(bookmark.title);
      setCurrentlyViewing({
        title: bookmark.title,
        type: "bookmark",
        id: bookmark.id,
      });
      // document.title = bookmark.title;
    }
    return () => {
      setCurrentlyViewing(null);
    };
  }, [bookmark?.title]);

  const updateBookmark = useBookmarkUpdate();

  const handleTogglePublic = async () => {
    await updateBookmark.mutateAsync({
      id: bookmark.id,
      is_public: !bookmark.is_public,
    });
    refetchBookmark();
  };
  const handleUpdateUrl = async () => {
    const url = prompt("URL:", bookmark.url);
    if (!url) {
      return;
    }
    await updateBookmark.mutateAsync({
      id: bookmark.id,
      url: url,
    });
    refetchBookmark();
  };
  const handleSave = async () => {
    if (status !== "edit") {
      // console.log("status not edit");
      return;
    }
    // console.log("do save");

    // TODO: have a "last saved" value, dont save if we don't need to (ie, if I deleted all the stuff I just typed)

    await updateBookmark.mutateAsync({
      id: bookmark.id,
      title: title,
    });
    refetchBookmark();
  };
  const saveAndExitEdit = async () => {
    handleSave();
    leaveEdit();
  };
  const leaveEdit = () => {
    setPresence({ editing: false });
    setStatus("view");
  };
  useEffect(() => {
    if (
      status === "edit" &&
      presenceIsCurrentlyEditing?.length &&
      presenceIsCurrentlyEditing?.[0]?.user !== user.id
    ) {
      alert("Sorry, being kicked because you are no longer the editor");
      leaveEdit();
    }
  }, [status, presenceIsCurrentlyEditing, user]);

  const handleStartEdit = () => {
    // console.log("presenceIsCurrentlyEditing", presenceIsCurrentlyEditing);
    if (presenceIsCurrentlyEditing?.length) {
      alert("Unable to start editing while somebody else already is");
      return;
    }
    setStatus("edit");
    setEditStatus("edit");
  };
  useEffect(() => {
    setPresence({ editing: status === "edit" ? true : false });
  }, [status]);

  useEffect(() => {
    if (
      status !== "edit" && // editing
      bookmark && // bookmark exists
      !bookmark.title?.length &&
      bookmark.owner === user.id
    ) {
      // no title or text
      handleStartEdit();
      // navigate({
      //   search: (old) => ({
      //     ...old,
      //     edit: undefined,
      //   }),
      //   replace: true,
      // });
    }
  }, [status, bookmark]);

  const handleKeyEvent = (key: any, e: any) => {
    //   if (!canEdit) return;

    switch (key) {
      case "ctrl+s":
      case "meta+s":
      case "cmd+s":
        handleSave();
        e.preventDefault();
        break;
      default:
        break;
    }
  };

  const embeds = [
    {
      re: new RegExp("^https?://docs\\.google\\.com/document/(.*)$"),
      replace: ["/edit", "/preview"],
    },
    {
      re: new RegExp("^https?://docs\\.google\\.com/spreadsheets/d/(.*)$"),
      replace: ["/edit", "/preview"],
    },
  ];

  // console.log("status:", status);
  // console.log("viewStatus:", viewStatus);
  // console.log("editStatus:", editStatus);

  if (!bookmark && isLoadingBookmark) {
    return (
      <div className="h-full flex items-center justify-center text-center">
        <div>
          <LoadingIcon />
          <div className="mt-2 text-sm text-gray-400">Loading Bookmark</div>
        </div>
      </div>
    );
  }

  if (!bookmark && isErrorBookmark) {
    if (existsObj?.data) {
      return (
        <div className="h-full flex items-center justify-center text-center">
          <div>
            <ExclamationIcon className="h-5 w-5 inline-block text-orange-500" />
            <div className="mt-2 text-sm text-orange-500">
              You do not have the necessary permissions to view this bookmark.
              <br />
              Contact the bookmark owner for access:{" "}
              <span className="font-bold">
                <Owner
                  id={existsObj.data.owner_id}
                  owner={existsObj.data.owner}
                />
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="h-full flex items-center justify-center text-center">
          <div>
            <ExclamationIcon className="h-5 w-5 inline-block text-red-400" />
            <div className="mt-2 text-sm text-red-400">
              Error loading bookmark
            </div>
          </div>
        </div>
      );
    }
  }

  if (!bookmark) {
    return null;
  }

  let previewUrl = bookmark.url;
  for (let embed of embeds) {
    if (embed.re.test(bookmark.url)) {
      previewUrl = previewUrl.replace(embed.replace[0], embed.replace[1]);
    }
  }

  return (
    <>
      <KeyboardEventHandler
        handleKeys={["meta+s", "ctrl+s", "meta"]}
        onKeyEvent={handleKeyEvent}
        handleFocusableElements
      />
      <div className="border-b bg-white">
        <div className="flex items-center space-x-1 px-1 py-1">
          <div className="">
            <PermissionsHeader
              obj={bookmark}
              ownerField={"owner"}
              user={user}
              updateObj={updateBookmark}
              refetch={refetchBookmark}
            />
          </div>
          <div className="text-sm">
            <Owner id={bookmark.owner_id} owner={bookmark.owned} />
          </div>
          <div className="pl-2">
            <CopyLink link={`/?type=bookmark&id=${bookmark.id}`} />
          </div>
          <div className="flex-auto"></div>
          {presenceIsCurrentlyEditing?.length ? (
            <div className="text-green-500 text-sm">
              Editing:{" "}
              <CurrentEditor user_id={presenceIsCurrentlyEditing[0].user} />
            </div>
          ) : null}
          {status === "edit" ? (
            <div className="">
              <span
                type="span"
                // className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-sm  "
                className="inline-flex justify-center rounded-md border border-transparent px-2 py-1 text-sm  text-gray-400 "
              >
                {updateBookmark.isLoading || waitingToSave
                  ? "Saving..."
                  : "Saved"}
              </span>
            </div>
          ) : null}
          {status === "view" ? (
            <>
              <div className="">
                <button
                  onClick={handleStartEdit}
                  className={`inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2`}
                >
                  Edit
                </button>
              </div>
            </>
          ) : null}
          {status === "edit" ? (
            <>
              <div className="">
                <button
                  onClick={saveAndExitEdit}
                  className={`inline-flex justify-center rounded-md border border-transparent bg-green-100 px-2 py-1 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2`}
                >
                  Done Editing
                </button>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div
        className="border-b"
        onDoubleClick={(event) => {
          if (status !== "edit") {
            handleStartEdit();
            setTimeout(() => {
              event.target.focus();
            }, 100);
          }
        }}
      >
        <input
          disabled={status === "view" ? true : false}
          className="w-full text-xl px-2 py-1 outline-none bg-white"
          onChange={(e) => {
            setTitle(e.target.value);
            // throttledFunction(realSaveNum + 1);
            setAutosaveNum((v) => v + 1);
          }}
          onKeyDown={(event) => {
            // on enter, save and exit editing
          }}
          value={title}
          placeholder="Untitled"
        />
      </div>
      <div className="h-full overflow-y-auto px-2 py-1 bg-white flex flex-col">
        {/* <div className={``}> */}
        {/* View */}
        {/* <div className="mt-2">
            Download as:{" "}
            <a
              href="#"
              className="text-blue-600 hover:text-blue-500 underline hover:underline"
            >
              {bookmarkname}
            </a>
          </div>
          <div className="mt-2">
            <a
              href="#"
              className="text-blue-600 hover:text-blue-500 underline hover:underline"
            >
              Get Signed URL (with expiration)
            </a>
          </div> */}

        <div className="h-full mt-2">
          <div className="border-b">
            <div className="flex">
              <div className="flex-auto">
                <input
                  className="w-full"
                  // onChange={(e) => setTitle(e.target.value)}
                  value={bookmark.url}
                  // onClick={handleUpdateUrl}
                  disabled
                />
              </div>
              <div className="p-1">
                <button
                  className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  onClick={handleUpdateUrl}
                >
                  change
                </button>
              </div>
              <div className="">
                <a
                  href={search.url}
                  target="_blank"
                  className="inline-flex w-full justify-center rounded-md px-1 text-sm font-medium hover:bg-black hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                >
                  <ExternalLinkIcon
                    className="h-5 w-5 text-violet-200 hover:text-violet-100"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </div>
          </div>
          <iframe
            key={search.url}
            ref={iframeRef}
            className="h-full w-full"
            src={previewUrl}
          ></iframe>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

const LoadingIcon = () => {
  return (
    <svg
      className="animate-spin h-5 w-5 inline-block text-gray-400"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

const Owner = ({ id, owner }) => {
  const { data: user } = useUserQuery({ id: owner ? null : id });
  return owner?.email?.split("@")[0] ?? user?.email?.split("@")[0] ?? "";
};

const CurrentEditor = ({ user_id }) => {
  const { data: user } = useUserQuery({ id: user_id });
  // console.log("info", { user, user_id });
  return `${user?.first_name} ${user?.last_name}`;
};

const isEditing = (presenceState) => {
  return Object.keys(presenceState)
    .map((k) => presenceState[k][0])
    .filter((v) => v.editing);
};

export default ViewBookmark;
