import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../lib/api";

const kvUpdate = async (keyvalue) => {
  // console.log("updating keyvalue", keyvalue);
  let supacall = supabase
    .from("keyvalues")
    .update(keyvalue)
    .eq("id", keyvalue.id);

  const { data, error } = await supacall;

  if (error) {
    throw new Error("Failed updating keyvalue");
  }

  return data;
};

export default function useKeyValueUpdate() {
  return useMutation((keyvalue) => kvUpdate(keyvalue), {});
}
