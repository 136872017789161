// @ts-nocheck
import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  LockClosedIcon,
  ShareIcon,
  GlobeAltIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";

import SharedDialog from "./SharedDialog";

const PermissionsHeader = ({
  obj,
  user,
  updateObj,
  ownerField = "owner_id",
  refetch,
}) => {
  // const updateDoc = useDocUpdate();
  const [editShared, setEditShared] = useState(false);

  const handleTogglePublic = async () => {
    await updateObj.mutateAsync({ id: obj.id, is_public: !obj.is_public });
    refetch();
  };

  // Public (everyone)
  // - Public (Make Private/Share)
  //   - only show Make Private for owner
  // Shared (owner + individuals [user_ids])
  // - Shared (Make Public/Make Private)
  // Private (owner-only)
  // - one-click to Public/Share
  // - Private (Make Public/Share)

  return (
    <>
      <SharedDialog
        isOpen={editShared}
        obj={obj}
        updateObj={updateObj}
        onComplete={() => {
          setEditShared(false);
          refetch();
        }}
      />
      <div className="flex align-center space-x-1">
        <button
          type="button"
          className={`inline-flex justify-center rounded-md border border-transparent px-2 py-1 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 ${
            obj?.is_public
              ? `bg-purple-100 text-purple-600 hover:bg-purple-200 focus-visible:ring-purple-500`
              : obj?.user_ids?.length
              ? `bg-blue-100 text-blue-900 hover:bg-blue-200 focus-visible:ring-blue-500`
              : `bg-cyan-100 text-cyan-900 hover:bg-cyan-200 focus-visible:ring-cyan-500`
          }`}
          onClick={() => {
            if (obj?.[ownerField] !== user.id) {
              alert("Unable to change permissions for a doc you do not own");
              return false;
            }
            handleTogglePublic();
          }}
          title={obj?.is_public ? "Make Private" : "Make Public"}
          // disabled={}
        >
          {obj?.is_public ? (
            <GlobeAltIcon
              className="w-4 h-4 inline-block mt-0.5 mr-1"
              title="Make Private"
            />
          ) : obj?.user_ids?.length ? (
            <UserGroupIcon
              className="w-4 h-4 inline-block mt-0.5 mr-1"
              title="Shared - Make Public"
            />
          ) : (
            <LockClosedIcon
              className="w-4 h-4 inline-block mt-0.5 mr-1"
              title="Make Public"
            />
          )}
        </button>
        <span
          className={`inline-flex justify-center rounded-md border border-transparent px-2 py-1 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 ${
            obj?.is_public
              ? `bg-white text-purple-600 focus-visible:ring-purple-500`
              : obj?.user_ids?.length
              ? `bg-white text-blue-900 focus-visible:ring-blue-500`
              : `bg-white text-cyan-900 focus-visible:ring-cyan-500`
          }`}
        >
          {obj?.is_public
            ? "Public"
            : obj?.user_ids?.length
            ? `Shared`
            : "Private"}
        </span>
        <button
          type="button"
          className={`inline-flex justify-center rounded-md border border-transparent px-2 py-1 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 ${
            obj?.is_public
              ? `bg-white text-slate-600 hover:ring-2 ring-inset focus-visible:ring-2`
              : obj?.user_ids?.length
              ? `bg-white text-slate-600 hover:ring-2 ring-inset focus-visible:ring-2`
              : `bg-white text-slate-900 hover:ring-2 ring-inset focus-visible:ring-2`
          }`}
          onClick={() => setEditShared(true)}
          disabled={obj?.[ownerField] !== user.id && obj?.owner !== user.id} // handle both "owner"/"owner_id" fields
        >
          {obj?.user_ids?.length ? obj.user_ids.length : null}
          <ShareIcon className="w-4 h-4 inline-block mt-0.5 mr-1" />
          Share
        </button>
      </div>
    </>
  );
};

export default PermissionsHeader;
