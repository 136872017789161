// @ts-nocheck
import React, { useEffect, useState, useRef, Fragment } from "react";
import { supabase } from "../lib/api";
import { v4 as uuidv4 } from "uuid";
import { isArray } from "lodash";

const SUBSCRIPTIONS = {
  // [channelName]: { channel, subs: [array, of, onUpdate-functions] }
};

const newSubscription = ({ table, idKey = "id", id, onUpdate }) => {
  const channelName = `watch:${table}:${idKey}:${id}:single`;
  let existingSub = SUBSCRIPTIONS[channelName];
  if (!existingSub) {
    // console.log("new sub:", channelName);
    SUBSCRIPTIONS[channelName] = { channel: null, subs: [] };
    existingSub = SUBSCRIPTIONS[channelName];

    const databaseFilter = {
      schema: "public",
      table: table,
      filter: `${idKey}=eq.${id}`,
      event: "UPDATE",
    };
    const channel = supabase
      .channel(channelName)
      .on("postgres_changes", databaseFilter, (payload) =>
        receivedDatabaseEvent(payload)
      )
      .subscribe();
    existingSub.channel = channel;

    const receivedDatabaseEvent = (event) => {
      // console.log("receivedDatabaseEvent", event);
      const { payload } = event;
      // notify all listeners for this key
      // console.log("fns:", SUBSCRIPTIONS[channelName].subs);
      for (let onUpdateFn of SUBSCRIPTIONS[channelName].subs) {
        onUpdateFn(event);
      }
    };
  } else {
    // console.log("existing sub:", channelName);
  }

  SUBSCRIPTIONS[channelName].subs.push(onUpdate);
  // console.log("SUBSCRIPTIONS (after add):", SUBSCRIPTIONS);
};
const removeSubscription = ({ table, idKey = "id", id, onUpdate }) => {
  // remove subscription, if none left, set timer to remove subscription
  const channelName = `watch:${table}:${idKey}:${id}:single`;
  const i = SUBSCRIPTIONS[channelName].subs.indexOf(onUpdate);
  // console.log("i:", i);
  // if (i > -1) {
  SUBSCRIPTIONS[channelName].subs.splice(i, 1);
  // }
  if (!SUBSCRIPTIONS[channelName].subs.length) {
    // SUBSCRIPTIONS[channelName].channel.unsubscribe();
    // delete SUBSCRIPTIONS[channelName];
  }
  // TODO: start timer if empty (cancel listening)
  // console.log("SUBSCRIPTIONS (after delete):", SUBSCRIPTIONS);
};
window.SUBSCRIPTIONS = SUBSCRIPTIONS;

export const useRealtimeSingle = ({
  initialRow = null, // ie the "query Row" (queries via useSingleQuery, or as part of a Multiple result) (TODO: rename to queryRow, include version?)
  table,
  id: tmpid,
  idKey = "id",
  onUpdate,
}) => {
  const [row, setRow] = useState(initialRow);

  useEffect(() => {
    if (tmpid?.length) {
      // console.log("useRealtimeSingle:", { id, table, idKey });

      const updateRow = (event) => {
        setRow(event.new);
        onUpdate && onUpdate(event);
      };

      let ids = isArray(tmpid) ? tmpid : [tmpid];
      let subs = [];
      for (let id of ids) {
        newSubscription({ table, id, idKey, onUpdate: updateRow });
      }
      return () => {
        for (let id of ids) {
          removeSubscription({ table, id, idKey, onUpdate: updateRow });
        }
      };
    } else {
      // console.log("No useRealtimeSingle", table);
    }
  }, [JSON.stringify(tmpid), table, idKey]);

  // console.log("onlineUsers", onlineUsers);
  // return { presenceState, channel, setPresence };
  return { row };
};

export default useRealtimeSingle;
