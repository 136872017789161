import { useQuery } from "react-query";
import { supabase } from "../lib/api";

import useRealtimeSingle from "./useRealtimeSingle";
import { useCallback } from "react";

const getSingle = async ({
  table,
  id,
  pkField = "id",
  select = `
            *`,
  ownerIdField = undefined, // if included, we will include the current use in the query
  nullOwner = false,

  // `
  // *,
  // owned_by:owner (
  //     email
  // )`
  // extra = null,
}) => {
  let owner;
  if (ownerIdField) {
    if (nullOwner) {
      owner = null;
    } else {
      // get the owner
      const { data: sessionData } = await supabase.auth.getSession();
      const { session } = sessionData;
      owner = session?.user?.id;
      if (!owner) {
        throw new Error("No user for query");
      }
    }
  }

  let supacall = supabase.from(table).select(select).eq(pkField, id);
  if (ownerIdField) {
    if (nullOwner === null) {
      supacall = supacall.is(ownerIdField, null);
    } else {
      // @ts-ignore
      supacall = supacall.eq(ownerIdField, owner);
    }
  }
  // @ts-ignore
  supacall = supacall.single();

  const { data, error } = await supacall;
  // const { data, error } = await supabase
  //   .from(table)
  //   .select(select)
  //   .eq(pkField, id)
  //   .single();

  // const {
  //   data: data2,
  //   error: error2,
  //   // @ts-ignore
  // } = await supabase.functions.invoke("check-pk-exists2", {
  //   body: { table, pk: id },
  // });
  // console.log("check-pk-exists:", { data2, error2 });

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error("Not found");
  }

  return data;
};

export default function useSingleQuery({
  table,
  pkField,
  id,
  select,
  ownerIdField,
  enabled = true,
  updateOnRealtime = true,
}) {
  const query = useQuery(
    [table, "id", id, { pkField, select, ownerIdField }],
    () => getSingle({ table, pkField, id, select, ownerIdField }),
    {
      enabled,
    }
  );

  // if (updateOnRealtime) {
  //   console.log(
  //     "useSingleQuery",
  //     query?.data,
  //     "id:",
  //     // @ts-ignore
  //     updateOnRealtime ? query?.data?.id : null
  //   );
  // } else {
  //   console.log("useSingleQuery", "no realtime", table);
  // }
  useRealtimeSingle({
    // @ts-ignore
    id: updateOnRealtime ? query?.data?.id : null,
    table,
    // idKey: pkField,
    onUpdate: () => {
      // console.log("collection updated (single-query)");
      query.refetch();
    },
  });

  return query;
}
