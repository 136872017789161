// @ts-nocheck
import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../lib/api";

const create = async ({ table, row }) => {
  const { data, error } = await supabase
    .from(table)
    .insert(row)
    .select()
    .maybeSingle();

  if (error) {
    console.error("Failed creating row in table:", error);
    throw new Error(`Failed creating row in table: ${table}`);
  }

  return data;
};

export default function useCollectionCreate() {
  return useMutation(({ table, row }) => create({ table, row }), {});
}
