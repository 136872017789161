// @ts-nocheck
import React, { useEffect, useState, useRef, useCallback } from "react";
import copy from "copy-to-clipboard";
import { LinkIcon } from "@heroicons/react/outline";
import toast from "react-hot-toast";

const CopyLink = ({ link }) => {
  return (
    <button
      className={`inline-flex justify-center rounded-md border border-transparent px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2`}
      title="Copy Internal Link"
      onClick={(v) => {
        link?.startsWith("/")
          ? copy(`${window.location.protocol}//${window.location.host}${link}`)
          : copy(link);
        toast.success("Copied link to clipboard");
      }}
      title="Copy Link to Clipboard"
    >
      <LinkIcon className={"w-4 h-4 mt-0.5 mr-1 inline-block"} /> Get Internal
      Link
    </button>
  );
};

export default CopyLink;
