import { useQuery } from "react-query";
import { supabase } from "../lib/api";

const getUser = async ({ id }) => {
    const { data, error } = await supabase
        .from("users")
        .select()
        .eq("id", id)
        .single();

    if (error) {
        throw new Error(error.message);
    }

    if (!data) {
        throw new Error("User not found");
    }

    return data;
};

export default function useUserQuery({ id }) {
    return useQuery(["users", "id", id], () => getUser({ id }), {
        enabled: !!id,
    });
}
