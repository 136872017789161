// @ts-nocheck
import React, { useEffect, useState, useRef, useCallback } from "react";
import copy from "copy-to-clipboard";
import { LinkIcon } from "@heroicons/react/outline";
import toast from "react-hot-toast";

const CopySearchLink = ({ linkType = "internal", type, title, id }) => {
  // type == internal, markdown
  switch (linkType) {
    case "md":
      return (
        <span
          className={`ml-1 cursor-pointer hover:underline`}
          title="Copy Internal Link"
          onClick={(v) => {
            copy(
              `<a href="${window.location.protocol}//${
                window.location.host
              }/?type=${type}&id=${id}">${title.replaceAll("/", "\\/")}`,
              { format: "text/html" }
            );
            toast.success("Copied link to clipboard");
          }}
          title="Copy <a> Title+Link to Clipboard"
        >
          w/ title
        </span>
      );

    case "internal":
    default:
      return (
        <span
          className={`ml-1 cursor-pointer hover:underline`}
          title="Copy Internal Link"
          onClick={(v) => {
            copy(
              `${window.location.protocol}//${window.location.host}/?type=${type}&id=${id}`
            );
            toast.success("Copied link to clipboard");
          }}
          title="Copy Just the Link to Clipboard"
        >
          link
        </span>
      );
  }
};

export default CopySearchLink;
