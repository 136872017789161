import { useState, useEffect } from "react";
import { supabase } from "./lib/api";
import Auth from "./components/Auth";
import Home from "./components/Home";
import { Toaster } from "react-hot-toast";

import "rc-tree/assets/index.css";

import {
  Link,
  MakeGenerics,
  Outlet,
  ReactLocation,
  Router,
  useMatch,
} from "@tanstack/react-location";

import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import useAppStore from "./store/app";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

// Set up a ReactLocation instance
const location = new ReactLocation();

function App() {
  window.supabase = supabase;
  return (
    <QueryClientProvider client={queryClient}>
      <Router
        location={location}
        routes={[
          {
            element: <Main />,
          },
        ]}
      >
        <Outlet />
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

const Main = () => {
  const [user, setUser] = useState(null);
  const setSession = useAppStore((s) => s.setSession);

  useEffect(() => {
    async function run() {
      console.log("Auth: checking session");
      const {
        data: sessionData,
        error: sessionError,
      } = await supabase.auth.getSession();
      const { session } = sessionData;
      // console.log("Session:", session, sessionError);
      setSession(session);
      setUser(session?.user ?? null);
    }
    run();
  }, []);

  useEffect(() => {
    let authListener;
    async function run() {
      console.log("Auth: starting auth listener");
      const { subscription } = supabase.auth.onAuthStateChange(
        async (event, session) => {
          // console.log("Auth: onAuthStateChange", session);
          // const currentUser = session?.user;
          // setUser(currentUser ?? null);
        }
      );
      authListener = subscription;
    }
    run();
    return () => {
      authListener?.unsubscribe();
    };
  }, [user]);

  return (
    <div className="min-w-full min-h-screen flex items-center justify-center bg-gray-200">
      <Toaster />
      {!user ? <Auth /> : <Home user={user} />}
    </div>
  );
};

export default App;
