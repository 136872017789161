import { useQuery } from "react-query";
import { supabase } from "../lib/api";

const getDoc = async ({ id }) => {
  const { data, error } = await supabase
    .from("docs")
    .select(
      `
            id,
            title,
            text,
            owner_id,
            is_public,
            user_ids,
            created_at,
            owner:owner_id (
                email
            )`
    )
    .eq("id", id)
    .single();

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error("Doc not found");
  }

  return data;
};

export default function useQueryDoc({ id }) {
  return useQuery(["docs", "id", id], () => getDoc({ id }), {
    enabled: !!id,
  });
}
