// @ts-nocheck
import React, { useRef, useState } from "react";

import create from "zustand";

const useAppStore = create((set) => ({
  // values
  collection: null, // id of collection
  session: null,
  currentlyViewing: null,

  // functions
  setCollection: (id) => set((state) => ({ collection: id })),
  setSession: (sesh) => set((state) => ({ session: sesh })),
  setCurrentlyViewing: (item) => set((state) => ({ currentlyViewing: item })),
}));

export default useAppStore;
