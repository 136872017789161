import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../lib/api";

import { v4 as uuidv4 } from "uuid";

const uploadFile = async (fileObj) => {
  const folder = uuidv4();
  const { data, error } = await supabase.storage
    .from("private-files")
    .upload(`${folder}/${fileObj.name}`, fileObj, {
      cacheControl: "1", // 1 second, not long because we want auth to be checked frequently
      upsert: false, // dont allow overwrite
    });
  if (error) {
    throw new Error("Failed uploading file");
  }
  console.log("data:", data);
  const { path } = data;
  console.log("Path:", path);
  return { path, filename: fileObj.name, folder };
};

export default function useUploadFile() {
  return useMutation((fileObj) => uploadFile(fileObj), {});
}
