// @ts-nocheck
import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../lib/api";

interface Bookmark {
  title: string;
  url: string;
  is_public: boolean;
  owner: string;
}

const bookmarkUpdate = async (bookmark: Bookmark) => {
  // console.log("bookmark to update:", bookmark);
  const { data, error } = await supabase
    .from("bookmarks")
    .update(bookmark)
    .eq("id", bookmark.id);

  if (error) {
    throw new Error("Failed updating bookmark");
  }

  return data;
};

export default function useBookmarkUpdate() {
  return useMutation((bookmark: Bookmark) => bookmarkUpdate(bookmark), {});
}
