import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../lib/api";

interface Doc {
  title: string;
  text: string;
  owner_id: string;
}

const createDoc = async (doc: Doc) => {
  const { data, error } = await supabase
    .from("docs")
    .insert(doc)
    .select()
    .maybeSingle();

  if (error) {
    throw new Error("Failed creating doc");
  }

  return data;
};

export default function useCreateDoc() {
  return useMutation((doc: Doc) => createDoc(doc), {});
}
