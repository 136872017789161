// @ts-nocheck
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  Fragment,
} from "react";
import { supabase } from "../lib/api";
import { UserGroupIcon } from "@heroicons/react/outline";
import { cx, css } from "@emotion/css";

import { useSearch, useNavigate, Link } from "@tanstack/react-location";

import { Menu, Transition } from "@headlessui/react";
import { Tooltip } from "react-tippy";

import useAppStore from "../../../store/app";

// import ArticleIcon from "@mui/icons-material/Article";
// import FormatSizeIcon from "@mui/icons-material/FormatSize";
// import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
// import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
// import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
// import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
// import StrikethroughS from "@mui/icons-material/StrikethroughS";
// import FormatBoldIcon from "@mui/icons-material/FormatBold";
// import FormatItalicIcon from "@mui/icons-material/FormatItalic";
// import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
// import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
// import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
// import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
// import AddLinkIcon from "@mui/icons-material/AddLink";
// import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
// import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
// import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";
// import HtmlIcon from "@mui/icons-material/Html";
// import CodeIcon from "@mui/icons-material/Code";
// import UndoIcon from "@mui/icons-material/Undo";
// import RedoIcon from "@mui/icons-material/Redo";
// import SaveIcon from "@mui/icons-material/Save";
// import NoteAddIcon from "@mui/icons-material/NoteAdd";
// import WarningIcon from "@mui/icons-material/Warning";
// import ErrorIcon from "@mui/icons-material/Error";
// import FormatClearIcon from "@mui/icons-material/FormatClear";
// import LayersClearIcon from "@mui/icons-material/LayersClear";
// import WorkspacesIcon from "@mui/icons-material/Workspaces";
// import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
// import HistoryIcon from "@mui/icons-material/History";
// import ViewColumnIcon from "@mui/icons-material/ViewColumn";
// import ListAltIcon from "@mui/icons-material/ListAlt";
// import DeleteIcon from "@mui/icons-material/Delete";

export const MenuBar = ({ editor }: any) => {
  const uploadButtonRef = React.useRef();

  if (!editor) {
    return null;
  }

  return (
    <>
      <div className="flex">
        <div className="flex items-center w-fit rounded-sm">
          <HeadingMenuBar
            {...{
              editor,
            }}
          />

          <MenuButtonIcon
            tooltipTitle="Bold"
            onClick={() => editor.chain().focus().toggleBold().run()}
            active={editor.isActive("bold")}
            // icon={<FormatBoldIcon />}
            icon={<i className="fa-solid fa-bold"></i>}
          />
          <MenuButtonIcon
            tooltipTitle="Italic"
            onClick={() => editor.chain().focus().toggleItalic().run()}
            active={editor.isActive("italic")}
            // icon={<FormatItalicIcon />}
            icon={<i className="fa-solid fa-italic"></i>}
          />
          <MenuButtonIcon
            tooltipTitle="Strikethrough"
            onClick={() => editor.chain().focus().toggleStrike().run()}
            active={editor.isActive("strike")}
            // icon={<StrikethroughS />}
            icon={<i className="fa-solid fa-strikethrough"></i>}
          />
          <MenuButtonIcon
            tooltipTitle="Clear Formatting"
            onClick={() => editor.chain().focus().unsetAllMarks().run()}
            // icon={<FormatClearIcon />}
            icon={<i className="fa-solid fa-text-slash"></i>}
          />
          <TextAlignMenuBar
            {...{
              editor,
            }}
          />
          <MenuButtonIcon
            tooltipTitle="Bullet List"
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            active={editor.isActive("bulletList")}
            // icon={<FormatListBulletedIcon />}
            icon={<i className="fa-solid fa-list-ul"></i>}
          />
          <MenuButtonIcon
            tooltipTitle="Numbered List"
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            active={editor.isActive("orderedList")}
            // icon={<FormatListNumberedIcon />}
            icon={<i className="fa-solid fa-list-ol"></i>}
          />
          {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
          <MenuButtonIcon
            tooltipTitle="Summary Panel (expandable)"
            onClick={() =>
              editor.chain().focus().setDetails({ open: true }).run()
            }
            active={editor.isActive("details")}
            // icon={<HorizontalSplitIcon />}
            icon={<i className="fa-solid fa-bars-staggered"></i>}
          />
          {/* <MenuButtonIcon
            tooltipTitle="Columns"
            onClick={() =>
              editor.isActive("column")
                ? editor
                    .chain()
                    .focus()
                    .insertContent([
                      {
                        type: "column",
                        attrs: {},
                        content: [
                          {
                            type: "paragraph",
                            attrs: {},
                            content: [
                              // {
                              //   type: "text",
                              //   text: "",
                              // },
                            ],
                          },
                        ],
                      },
                    ])
                    .run()
                : editor
                    .chain()
                    .focus()
                    .insertContent([
                      {
                        type: "columns",
                        attrs: {},
                        content: [
                          {
                            type: "column",
                            attrs: {},
                            content: [
                              {
                                type: "paragraph",
                                attrs: {},
                                content: [
                                  // {
                                  //   type: "text",
                                  //   text: "",
                                  // },
                                ],
                              },
                            ],
                          },
                          {
                            type: "column",
                            attrs: {},
                            content: [
                              {
                                type: "paragraph",
                                attrs: {},
                                content: [
                                  // {
                                  //   type: "text",
                                  //   text: "",
                                  // },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ])
                    .run()
            }
            active={editor.isActive("column")}
            // disabled={editor.isActive("column")}
            icon={<ViewColumnIcon />}
          /> */}
          <MenuButtonIcon
            tooltipTitle="Code"
            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            active={editor.isActive("codeBlock")}
            // icon={<CodeIcon />}
            icon={<i className="fa-solid fa-code"></i>}
          />
          {/* <MenuButtonIcon
            tooltipTitle="Internal Note (not visible to customers)"
            onClick={() => editor.chain().focus().toggleInternalNote().run()}
            active={editor.isActive("internalNote")}
            // icon={<NoteAddIcon />}
            icon={<i className="fa-solid fa-bold"></i>}
          /> */}
          <MenuButtonIcon
            tooltipTitle="Blockquote"
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            active={editor.isActive("blockquote")}
            // icon={<FormatQuoteIcon />}
            icon={<i className="fa-solid fa-quote-left"></i>}
          />
          {/* <MenuButtonIcon
            tooltipTitle={
              editor.isActive("link") ? (
                <>
                  Link: <br />
                  {editor.getAttributes("link").href}
                </>
              ) : (
                "Link"
              )
            }
            onClick={() => {
              // this.editor.getAttributes('link').href

              // determine if a link is selected?
              // - already a link?
              let link = "";
              let val: any;
              if (editor.isActive("link")) {
                link = editor.getAttributes("link").href;
                val = prompt("Link:", link);
                if (val === null) return null;
                if (!val.length) {
                  editor
                    .chain()
                    .focus()
                    .extendMarkRange("link")
                    .unsetLink()
                    .run();
                  return;
                }
                editor
                  .chain()
                  .focus()
                  .extendMarkRange("link")
                  .setLink({ href: val })
                  .run();
                // editor.chain().focus().unsetLink().run();
                return;
              }
              val = prompt("Link:", link);
              if (!val?.length) return;
              editor.chain().focus().setLink({ href: val }).run();
            }}
            active={editor.isActive("link")} // editor.getAttributes('link').href
            icon={<AddLinkIcon />}
          /> */}
          {/* <MenuButtonIcon
            tooltipTitle="Image"
            // onClick={() => editor.chain().focus().toggleStrike().run()}
            // @ts-ignore
            onClick={() => {
              // @ts-ignore
              uploadButtonRef.current?.openDialog();

              // editor.commands.setImage({ src });
              // editor.chain().focus().setImage({ src: SRC }).run();
            }}
            active={editor.isActive("image")}
            icon={<AddPhotoAlternateIcon />}
          /> */}
          {/* <MenuButtonIcon
            tooltipTitle="Video"
            // onClick={() => editor.chain().focus().toggleStrike().run()}
            // @ts-ignore
            onClick={() => {
              // // @ts-ignore
              // uploadButtonRef.current?.openDialog();
              // editor.commands.setImage({ src });
              const video_src = prompt("Video URL:", ""); //VIDEO_SRC);
              if (!video_src) return;
              editor.chain().focus().setVideo({ src: video_src }).run();
            }}
            active={editor.isActive("video")}
            icon={<OndemandVideoIcon />}
          /> */}
          <MenuButtonIcon
            tooltipTitle="Horizontal Line"
            onClick={() => editor.chain().focus().setHorizontalRule().run()}
            // icon={<HorizontalRuleIcon />}
            icon={<i class="bi bi-hr"></i>}
          />
          {/* <Divider orientation="vertical" variant="middle" flexItem /> */}
          <MenuButtonIcon
            tooltipTitle="Undo"
            onClick={() => editor.chain().focus().undo().run()}
            disabled={editor.can().undo() ? false : true}
            // icon={<UndoIcon />}
            icon={<i class="fa-solid fa-rotate-left"></i>}
          />
          <MenuButtonIcon
            tooltipTitle="Redo"
            onClick={() => editor.chain().focus().redo().run()}
            disabled={editor.can().redo() ? false : true}
            // icon={<RedoIcon />}
            icon={<i class="fa-solid fa-rotate-right"></i>}
          />
          {/* <MenuButtonIcon
            tooltipTitle="Clear Node (does not delete text)"
            onClick={() => editor.chain().focus().clearNodes().run()}
            // icon={<LayersClearIcon />}
            icon={<i class="fa-solid fa-rotate-left"></i>}
          />
          <MenuButtonIcon
            tooltipTitle={
              <>
                Highlight Parent Node. <br />
                Current: --
              </>
            }
            onClick={() => editor.chain().focus().selectParentNode().run()}
            icon={<HighlightAltIcon />}
          />

          <TemplateMenuBar
            {...{
              editor,
            }}
          />
          <MenuButtonIcon
            tooltipTitle="Delete Article"
            // @ts-ignore
            onClick={async () => {
              // @ts-ignore
              if (!window.confirm("Delete article, are you sure?")) {
                return;
              }

              await deleteArticleMutation.mutateAsync({ id: article });
              queryClient.invalidateQueries(["docs"]);

              window.location.href = "/";
            }}
            icon={<DeleteIcon />}
          /> */}
        </div>
      </div>
    </>
  );
};

export const MenuButtonIcon = ({
  active,
  activeBg = null,
  onClick,
  icon,
  text,
  disabled = false,
  tooltipTitle,
}: any) => {
  return (
    <Tooltip title={tooltipTitle} arrow>
      <div
        className={`p-2 cursor-pointer ${active ? "bg-blue-100" : ""} ${
          disabled ? "opacity-5" : ""
        }`}
        // sx={{
        //   p: 1.5,
        //   cursor: "pointer",
        //   color: active ? "black" : "inherit",
        //   background: active ? activeBg || "rgba(0,0,0,0.1)" : undefined,
        //   "& svg": {
        //     display: "block",
        //   },
        //   opacity: disabled ? 0.5 : undefined,
        // }}
        onClick={disabled ? null : onClick}
      >
        {icon}
        {text?.length ? ` ${text}` : null}
      </div>
    </Tooltip>
  );
};

const TextAlignMenuBar = ({ editor }) => {
  let active: any = false;
  let activeBg: any = null;

  const arrowRef = useRef();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  // TODO: use a switch() and editor.getAttributes()
  let isActive: boolean = false;
  let CurrentIcon: any = <i className="fa-solid fa-align-left"></i>;
  if (editor.isActive({ textAlign: "left" })) {
    isActive = true;
  }
  if (editor.isActive({ textAlign: "center" })) {
    isActive = true;
    CurrentIcon = <i className="fa-solid fa-align-center"></i>;
  }
  if (editor.isActive({ textAlign: "right" })) {
    isActive = true;
    CurrentIcon = <i className="fa-solid fa-align-right"></i>;
  }
  if (editor.isActive({ textAlign: "justify" })) {
    isActive = true;
    CurrentIcon = <i className="fa-solid fa-align-justify"></i>;
  }

  return (
    <div className="is-menu text-left">
      <Menu as="div" className="relative inline-block text-left">
        <div style={{}}>
          <Menu.Button className="inline-flex w-full rounded-md px-1 text-sm font-medium hover:bg-black hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <MenuButtonIcon
              tooltipTitle="Left-Align"
              // onClick={() => editor.chain().focus().setTextAlign("left").run()}
              // active={editor.isActive({ textAlign: "left" })}
              active
              icon={CurrentIcon}
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-50 left-0 mt-0 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                <MenuButtonIcon
                  tooltipTitle="Left-Align"
                  onClick={() =>
                    editor.chain().focus().setTextAlign("left").run()
                  }
                  // active={editor.isActive({ textAlign: "left" })}
                  active
                  // icon={<FormatAlignLeftIcon />}
                  icon={<i className="fa-solid fa-align-left"></i>}
                  text={"Left"}
                />
              </Menu.Item>
              <Menu.Item>
                <MenuButtonIcon
                  tooltipTitle="Center-Align"
                  onClick={() =>
                    editor.chain().focus().setTextAlign("center").run()
                  }
                  // active={editor.isActive({ textAlign: "left" })}
                  active
                  // icon={<FormatAlignLeftIcon />}
                  icon={<i className="fa-solid fa-align-center"></i>}
                  text={"Center"}
                />
              </Menu.Item>
              <Menu.Item>
                <MenuButtonIcon
                  tooltipTitle="Right-Align"
                  onClick={() =>
                    editor.chain().focus().setTextAlign("right").run()
                  }
                  // active={editor.isActive({ textAlign: "left" })}
                  active
                  // icon={<FormatAlignLeftIcon />}
                  icon={<i className="fa-solid fa-align-right"></i>}
                  text={"Right"}
                />
              </Menu.Item>
              <Menu.Item>
                <MenuButtonIcon
                  tooltipTitle="Justify"
                  onClick={() =>
                    editor.chain().focus().setTextAlign("justify").run()
                  }
                  // active={editor.isActive({ textAlign: "left" })}
                  active
                  // icon={<FormatAlignLeftIcon />}
                  icon={<i className="fa-solid fa-align-justify"></i>}
                  text={"Justify"}
                />
              </Menu.Item>

              {/* 
              
                          <MenuButtonIcon
                            tooltipTitle="Left-Align"
                            onClick={() =>
                              editor.chain().focus().setTextAlign("left").run()
                            }
                            active={editor.isActive({ textAlign: "left" })}
                            icon={<FormatAlignLeftIcon />}
                          />
                          <MenuButtonIcon
                            tooltipTitle="Center-Align"
                            onClick={() =>
                              editor
                                .chain()
                                .focus()
                                .setTextAlign("center")
                                .run()
                            }
                            active={editor.isActive({ textAlign: "center" })}
                            icon={<FormatAlignCenterIcon />}
                          />
                          <MenuButtonIcon
                            tooltipTitle="Right-Align"
                            onClick={() =>
                              editor.chain().focus().setTextAlign("right").run()
                            }
                            active={editor.isActive({ textAlign: "right" })}
                            icon={<FormatAlignRightIcon />}
                          />
                          <MenuButtonIcon
                            tooltipTitle="Justify"
                            onClick={() =>
                              editor
                                .chain()
                                .focus()
                                .setTextAlign("justify")
                                .run()
                            }
                            active={editor.isActive({ textAlign: "justify" })}
                            icon={<FormatAlignJustifyIcon />}
                          />
 */}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

const HeadingMenuBar = ({ editor }) => {
  let active: any = false;
  let activeBg: any = null;

  const arrowRef = useRef();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  let isActive: any = false;
  let level: any;
  if (editor.isActive("paragraph")) {
    isActive = true;
  } else if (editor.isActive("heading")) {
    isActive = true;
    level = editor.getAttributes("heading").level;
  }

  return (
    <div className="is-menu text-left">
      <Menu as="div" className="relative inline-block text-left">
        <div style={{}}>
          <Menu.Button className="inline-flex w-36 p-2 pl-4 rounded-md px-1 text-sm font-medium hover:bg-black hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <HComp level={level} />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-50 left-0 mt-0 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                <h1
                  onClick={(e) =>
                    editor.chain().focus().toggleHeading({ level: 1 }).run()
                  }
                  style={{
                    background: level === 1 ? "rgba(0,0,0,0.1)" : undefined,
                  }}
                >
                  Heading 1
                </h1>
              </Menu.Item>
              <Menu.Item>
                <h2
                  onClick={(e) =>
                    editor.chain().focus().toggleHeading({ level: 2 }).run()
                  }
                  style={{
                    background: level === 2 ? "rgba(0,0,0,0.1)" : undefined,
                  }}
                >
                  Heading 2
                </h2>
              </Menu.Item>
              <Menu.Item>
                <h3
                  onClick={(e) =>
                    editor.chain().focus().toggleHeading({ level: 3 }).run()
                  }
                  style={{
                    background: level === 3 ? "rgba(0,0,0,0.1)" : undefined,
                  }}
                >
                  Heading 3
                </h3>
              </Menu.Item>
              <Menu.Item>
                <h4
                  onClick={(e) =>
                    editor.chain().focus().toggleHeading({ level: 4 }).run()
                  }
                  style={{
                    background: level === 4 ? "rgba(0,0,0,0.1)" : undefined,
                  }}
                >
                  Heading 4
                </h4>
              </Menu.Item>
              <Menu.Item>
                <h5
                  onClick={(e) =>
                    editor.chain().focus().toggleHeading({ level: 5 }).run()
                  }
                  style={{
                    background: level === 5 ? "rgba(0,0,0,0.1)" : undefined,
                  }}
                >
                  Heading 5
                </h5>
              </Menu.Item>
              <Menu.Item>
                <h6
                  onClick={(e) =>
                    editor.chain().focus().toggleHeading({ level: 6 }).run()
                  }
                  style={{
                    background: level === 6 ? "rgba(0,0,0,0.1)" : undefined,
                  }}
                >
                  Heading 6
                </h6>
              </Menu.Item>
              <Menu.Item>
                <p
                  onClick={(e) => editor.chain().focus().setParagraph().run()}
                  style={{
                    background:
                      level === undefined ? "rgba(0,0,0,0.1)" : undefined,
                  }}
                >
                  Normal text
                </p>
              </Menu.Item>

              {/* <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleAddType({
                      type: "url",
                      url: "",
                      title: "",
                    })}
                    className={`${
                      active ? "bg-purple-500 text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    New URL
                  </button>
                )}
              </Menu.Item> */}
              {/* <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={onNewFolder}
                    className={`${
                      active ? "bg-purple-500 text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    New Folder
                  </button>
                )}
              </Menu.Item> */}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

const HComp = ({ level }: any) => {
  const sx = {};
  switch (level) {
    case 1:
      return <h1>Heading 1</h1>;
    case 2:
      return <h2>Heading 2</h2>;
    case 3:
      return <h3>Heading 3</h3>;
    case 4:
      return <h4>Heading 4</h4>;
    case 5:
      return <h5>Heading 5</h5>;
    case 6:
      return <h6>Heading 6</h6>;
    default:
      return <p>Normal text</p>;
  }
};

export default MenuBar;
