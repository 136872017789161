// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import { supabase } from "../lib/api";
import Tree, { TreeNode } from "rc-tree";
import {
  FolderIcon,
  FolderOpenIcon,
  FolderRemoveIcon,
  CollectionIcon,
  DocumentTextIcon,
  QuestionMarkCircleIcon,
  ExternalLinkIcon,
} from "@heroicons/react/outline";
import { cx, css } from "@emotion/css";

import { useSearch } from "@tanstack/react-location";

import useAppStore from "../store/app";
import useQueryDoc from "../hooks/useQueryDoc";
import useDocUpdate from "../hooks/useDocUpdate";

const ViewUrl = ({}) => {
  const search = useSearch();

  const iframeRef = useRef();
  const setCurrentlyViewing = useAppStore((s) => s.setCurrentlyViewing);

  useEffect(() => {
    // unable to get title of iframe (security)
    // document.title = `url: ${search.url}`;
    setCurrentlyViewing({
      title: `${search.url}`, // cannot get a good title from the URL (maybe make a proxied request through the server, or embedly?)
      type: "url",
      url: search.url,
    });
    return () => {
      setCurrentlyViewing(null);
    };
  }, []);

  const embeds = [
    {
      re: new RegExp("^https?://docs\\.google\\.com/document/(.*)$"),
      replace: ["/edit", "/preview"],
    },
    {
      re: new RegExp("^https?://docs\\.google\\.com/spreadsheets/d/(.*)$"),
      replace: ["/edit", "/preview"],
    },
  ];

  let previewUrl = search.url;
  for (let embed of embeds) {
    if (embed.re.test(search.url)) {
      previewUrl = previewUrl.replace(embed.replace[0], embed.replace[1]);
    }
  }

  return (
    <>
      <div className="border-b">
        <div className="flex">
          <div className="flex-auto">
            <input
              className="w-full"
              // onChange={(e) => setTitle(e.target.value)}
              value={search.url}
              disabled
            />
          </div>
          <div className="">
            <a
              href={search.url}
              target="_blank"
              className="inline-flex w-full justify-center rounded-md px-1 text-sm font-medium hover:bg-black hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              <ExternalLinkIcon
                className="h-5 w-5 text-violet-200 hover:text-violet-100"
                aria-hidden="true"
              />
            </a>
          </div>
        </div>
      </div>
      <iframe
        key={search.url}
        ref={iframeRef}
        className="h-full"
        src={previewUrl}
      ></iframe>
      {/* <div className="h-full overflow-y-auto">
                <iframe className="h-full" src={search.url}></iframe>
            </div> */}
    </>
  );
};

export default ViewUrl;
