// @ts-nocheck
import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../lib/api";

interface File {
  title: string;
  is_public: boolean;
  owner: string;
}

const fileUpdate = async (file: File) => {
  // console.log("file to update:", file);
  const { data, error } = await supabase
    .from("files")
    .update(file)
    .eq("id", file.id);

  if (error) {
    throw new Error("Failed updating file");
  }

  return data;
};

export default function useFileUpdate() {
  return useMutation((file: File) => fileUpdate(file), {});
}
