// @ts-nocheck
import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../lib/api";

interface Doc {
  title: string;
  text: string;
  owner_id: string;
}

const docUpdate = async (doc: Doc) => {
  // console.log("doc to update:", doc);
  const { data, error } = await supabase
    .from("docs")
    .update(doc)
    .eq("id", doc.id);

  if (error) {
    throw new Error("Failed updating doc");
  }

  return data;
};

export default function useDocUpdate() {
  return useMutation((doc: Doc) => docUpdate(doc), {});
}
