// @ts-nocheck
import { Dialog, Combobox, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import useSingleQuery from "../hooks/useSingleQuery";
import { useList } from "react-use";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useKeyValueUpdate from "../hooks/useKeyValueUpdate";
import { supabase } from "../lib/api";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

import useUsersQuery from "../hooks/useUsersQuery";

const SharedDialog = ({ obj, updateObj, isOpen, onComplete }) => {
  const [
    items,
    arr,
    // {
    //   set,
    //   push,
    //   updateAt,
    //   insertAt,
    //   update,
    //   updateFirst,
    //   upsert,
    //   sort,
    //   filter,
    //   removeAt,
    //   clear,
    //   reset,
    // },
  ] = useList([]);

  // console.log("isOpen:", isOpen);

  useEffect(() => {
    console.log("sharedDialog"); //, kvSidebar);
    if (obj) {
      arr.set(obj?.user_ids ?? []);
    }
  }, [obj]);

  function closeModal() {
    onComplete(null);
  }

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = useCallback(
    (result) => {
      // alert(1);
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const newItems = reorder(
        items,
        result.source.index,
        result.destination.index
      );

      arr.set(newItems);
    },
    [items]
  );

  const handleSave = async () => {
    if (!obj) {
      return false;
    }
    await updateObj.mutateAsync({
      id: obj.id,
      user_ids: items,
    });
    onComplete();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Share
                </Dialog.Title>
                <div className="mt-2 relative">
                  {obj.is_public ? (
                    <div className="rounded mt-4 mb-4 p-2 border-2 border-blue-100 bg-blue-100/20 text-sm">
                      When Public, it doesn't really matter who it is shared
                      with...
                    </div>
                  ) : null}
                  <div className="mt-4">
                    <SearchUsers
                      onChange={(v) => {
                        arr.push(v.id);
                      }}
                    />
                  </div>
                  <div className="mt-4 mb-4">
                    {items.map((item, index) => (
                      <User
                        key={item}
                        item={item}
                        index={index}
                        onRemove={() => arr.remove(index)}
                      />
                    ))}
                  </div>
                </div>
                <hr />
                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-blue-900 hover:bg-red-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 mr-4"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={handleSave}
                  >
                    Save Changes
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const SearchUsers = ({ onChange }) => {
  const { data: users } = useUsersQuery();
  const people = users ?? [];

  const [query, setQuery] = useState("");

  const filteredPeople =
    query === ""
      ? people
      : people.filter((person) =>
          person.email
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <div className="">
      <Combobox value={null} onChange={onChange}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
              displayValue={(person) => person?.email}
              onChange={(event) => setQuery(event.target.value)}
              placeholder="Search Users by Email"
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <SelectorIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredPeople.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredPeople.map((person) => (
                  <Combobox.Option
                    key={person.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-2 pr-4 ${
                        active ? "bg-teal-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={`block truncate font-normal`}>
                          {person.email}
                        </span>
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

const User = ({ item, index, onRemove }) => {
  const {
    data: user,
    isLoading: userIsLoading,
    isError: userIsError,
    refetch: userRefetch,
  } = useSingleQuery({
    table: "users",
    id: item,
    select: `id, email`,
    updateOnRealtime: false,
  });

  return (
    <div
      className={classNames("rounded p-2")}
      // style={{ ...provided.draggableProps.style, left: 0, top: 0 }}
    >
      {user?.email ?? "Loading..."}{" "}
      <span
        className="ml-2 underline cursor-pointer text-blue-400 hover:text-blue-300"
        onClick={onRemove}
      >
        remove
      </span>
    </div>
  );
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default SharedDialog;
