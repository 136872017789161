// @ts-nocheck
import { useEffect, useState, useRef } from "react";
import { supabase } from "../lib/api";
import RecoverPassword from "./RecoverPassword";
import TodoItem from "./TodoItem";
import Sidebar from "./Sidebar";
import Docs from "./Docs";
import Files from "./Files";
import Search from "./Search";
import ViewFile from "./ViewFile";
import ViewBookmark from "./ViewBookmark";
import ViewDoc from "./ViewDoc";
import ViewCollection from "./ViewCollection";
import ViewUrl from "./ViewUrl";
import {
  SelectorIcon,
  RewindIcon,
  FastForwardIcon,
} from "@heroicons/react/outline";

import { Link, useSearch } from "@tanstack/react-location";
import { Resizable } from "re-resizable";

const Home = ({ user }) => {
  const [recoveryToken, setRecoveryToken] = useState(null);
  const [todos, setTodos] = useState([]);
  const newTaskTextRef = useRef();
  const [errorText, setError] = useState("");

  const search = useSearch();

  const resizeRef = useRef();
  const resizeRef2 = useRef();

  useEffect(() => {
    /* Recovery url is of the form
     * <SITE_URL>#access_token=x&refresh_token=y&expires_in=z&token_type=bearer&type=recovery
     * Read more on https://supabase.com/docs/reference/javascript/reset-password-email#notes
     */
    let url = window.location.hash;
    let query = url.slice(1);
    let result = {};

    query.split("&").forEach((part) => {
      const item = part.split("=");
      result[item[0]] = decodeURIComponent(item[1]);
    });

    if (result.type === "recovery") {
      setRecoveryToken(result.access_token);
    }

    fetchTodos().catch(console.error);
  }, []);

  const fetchTodos = async () => {
    // let { data: todos, error } = await supabase
    //     .from("todos")
    //     .select("*")
    //     .order("id", { ascending: false });
    // if (error) console.log("error", error);
    // else setTodos(todos);
  };

  const deleteTodo = async (id) => {
    try {
      await supabase.from("todos").delete().eq("id", id);
      setTodos(todos.filter((x) => x.id !== id));
    } catch (error) {
      console.log("error", error);
    }
  };

  const addTodo = async () => {
    let taskText = newTaskTextRef.current.value;
    let task = taskText.trim();
    if (task.length <= 3) {
      setError("Task length should be more than 3!");
    } else {
      let { data: todo, error } = await supabase
        .from("todos")
        .insert({ task, user_id: user.id })
        .single();
      if (error) setError(error.message);
      else {
        setTodos([todo, ...todos]);
        setError(null);
        newTaskTextRef.current.value = "";
      }
    }
  };

  const handleLogout = async () => {
    supabase.auth.signOut().catch(console.error);
  };
  const {
    setToMin: setToMin1,
    setToMax: setToMax1,
    handleResize: handleResize,
  } = useResizeDblClick(resizeRef);
  const {
    setToMin: setToMin2,
    setToMax: setToMax2,
    handleResize: handleResize2,
  } = useResizeDblClick(resizeRef2);
  const handleMinAll = () => {
    setToMin1();
    setToMin2();
  };
  const handleMaxAll = () => {
    setToMax1();
    setToMax2();
  };

  let Viewer = () => null;
  let viewerKey = "root";
  switch (search.type) {
    case "doc":
      Viewer = ViewDoc;
      viewerKey = search.id;
      break;
    case "bookmark":
      Viewer = ViewBookmark;
      viewerKey = search.id;
      break;
    case "file":
      Viewer = ViewFile;
      viewerKey = search.id;
      break;
    case "collection":
      Viewer = ViewCollection;
      viewerKey = search.id;
      break;
    case "url":
      Viewer = ViewUrl;
      viewerKey = search.url;
      break;
    default:
      break;
  }

  return recoveryToken ? (
    <RecoverPassword
      token={recoveryToken}
      setRecoveryToken={setRecoveryToken}
    />
  ) : (
    <div className={"w-screen fixed flex flex-col min-h-screen bg-gray-50"}>
      <div
        className={"flex flex-row flex-grow divide-x"}
        style={{ height: "calc(100vh - 11.5rem)" }}
      >
        {/* <div className="flex-auto border flex flex-col"> */}
        <Resizable
          ref={resizeRef}
          defaultSize={{
            width: 320,
            height: "100%",
          }}
          minWidth={82}
          maxWidth={320}
          enable={{
            top: false,
            right: true,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          handleStyles={{
            right: {
              height: "auto",
              width: "auto",
              // background: "red",
              // top: "80%",
              top: "calc(100% - 56px)",
              right: "-10px",
              border: "1px solid #eee",
              borderRadius: "4px",
              background: "#efefef",
              zIndex: 99,
            },
          }}
          // handleComponent={{
          //   right: <SelectorIcon className="w-4 h-4 transform rotate-90" />,
          // }}
          onResizeStart={handleResize}
          className="flex-none flex flex-col divide-y"
        >
          <Search user={user} />
          {/* <Sidebar /> */}
          <header
            className={
              "flex h-9 justify-between items-center px-4 py-1 overflow-x-hidden"
            }
          >
            <button
              onClick={handleLogout}
              className={
                "flex justify-center px-1 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
              }
            >
              Logout
            </button>
            <span className={"font-sans"}>
              <Link to="/">VoIPLabs</Link>
            </span>
          </header>
        </Resizable>
        {/* </div> */}
        {/* <div className="flex-none flex flex-col w-72 border-r"> */}
        {/* <Resizable
          ref={resizeRef2}
          defaultSize={{
            width: 320,
            height: "100%",
          }}
          minWidth={82}
          maxWidth={320}
          enable={{
            top: false,
            right: true,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
            zIndex: 99,
          }}
          handleStyles={{
            right: {
              height: "auto",
              width: "auto",
              // background: "red",
              // top: "80%",
              top: "calc(100% - 56px)",
              right: "-10px",
              border: "1px solid #eee",
              borderRadius: "4px",
              background: "#efefef",
            },
          }}
          handleComponent={{
            right: <SelectorIcon className="w-4 h-4 transform rotate-90" />,
          }}
          onResizeStart={handleResize2}
          className="flex-none flex flex-col divide-y"
        > */}
        {/* Search (List) QA and Docs */}
        {/* <Files user={user} /> */}
        {/* <Docs user={user} /> */}
        {/* <Search user={user} />
          <header className={"flex h-9 px-4 py-1"}>
            <button
              onClick={handleMinAll}
              className="inline-flex justify-center rounded-md px-1 text-sm font-medium hover:bg-black hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              <RewindIcon className="h-5 w-5 text-violet-200 hover:text-violet-100" />
            </button>
            <div className="flex-auto"></div>
            <button
              onClick={handleMaxAll}
              className="inline-flex justify-center rounded-md px-1 text-sm font-medium hover:bg-black hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              <FastForwardIcon className="h-5 w-5 text-violet-200 hover:text-violet-100" />
            </button>
          </header> */}
        {/* </Resizable> */}
        {/* </div> */}
        <div className="h-screen flex flex-col flex-auto bg-white">
          <Viewer key={viewerKey} user={user} />

          {/* <div
                        className={`p-2 border flex-grow grid gap-2 ${
                            todos.length ? "auto-rows-min" : ""
                        } grid-cols-1 h-2/3 overflow-y-scroll first:mt-8`}
                    >
                        {todos.length ? (
                            todos.map((todo) => (
                                <TodoItem
                                    key={todo.id}
                                    todo={todo}
                                    onDelete={() => deleteTodo(todo.id)}
                                />
                            ))
                        ) : (
                            <span
                                className={
                                    "h-full flex justify-center items-center"
                                }
                            >
                                You do not have any tasks yet!
                            </span>
                        )}
                    </div>
                    <div
                        className={
                            "border max-w-sm self-center px-4 py-2 mt-4 text-center text-sm bg-red-100 border-red-300 text-red-400"
                        }
                    >
                        test error text
                    </div> */}
        </div>
      </div>
      {/* <div className={"flex m-4 mt-0 h-10"}>
                <input
                    ref={newTaskTextRef}
                    type="text"
                    onKeyUp={(e) => e.key === "Enter" && addTodo()}
                    className={
                        "bg-gray-200 border px-2 border-gray-300 w-full mr-4"
                    }
                />
                <button
                    onClick={addTodo}
                    className={
                        "flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                    }
                >
                    Add
                </button>
            </div> */}
    </div>
  );
};

const useResizeDblClick = (resizeRef) => {
  const minWidth = 82;
  const maxWidth = 320;
  const [resizeCount, setResizeCount] = useState(0);
  const handleResize = (resize) => {
    setResizeCount((v) => {
      return v + 1;
    });
    setTimeout(() => {
      setResizeCount((v) => {
        return v - 1;
      });
    }, 450);
  };
  const setToMin = () => {
    resizeRef.current.updateSize({
      width: minWidth,
      height: "100%",
    });
  };
  const setToMax = () => {
    resizeRef.current.updateSize({
      width: maxWidth,
      height: "100%",
    });
  };
  useEffect(() => {
    if (resizeCount == 2) {
      console.log("resizeRef.current", resizeRef.current);
      if (resizeRef.current.state.width < minWidth + 12) {
        resizeRef.current.updateSize({
          width: maxWidth,
          height: "100%",
        });
      } else {
        resizeRef.current.updateSize({
          width: minWidth,
          height: "100%",
        });
      }
    }
  }, [resizeCount]);

  return { setToMin, setToMax, handleResize };
};

export default Home;
