// @ts-nocheck
import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  LockClosedIcon,
  ShareIcon,
  GlobeAltIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";

const PermissionIcon = ({ obj }) => {
  return (
    <span
      className={`ml-1 inline-flex justify-center rounded-full border border-transparent px-0.5 py-0.5 text-xs font-thin ${
        obj?.is_public
          ? `bg-purple-100 text-purple-600`
          : obj?.user_ids?.length
          ? `bg-blue-100 text-blue-900`
          : `bg-cyan-100 text-cyan-900`
      }`}
      title={
        obj?.is_public ? "Public" : obj?.user_ids?.length ? "Shared" : "Private"
      }
    >
      {obj?.is_public ? (
        <GlobeAltIcon className="w-3 h-3 inline-block" />
      ) : obj?.user_ids?.length ? (
        <UserGroupIcon className="w-3 h-3 inline-block" />
      ) : (
        <LockClosedIcon className="w-3 h-3 inline-block" />
      )}
    </span>
  );
};

export default PermissionIcon;
