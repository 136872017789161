import { useQuery } from "react-query";
import { supabase } from "../lib/api";

const getBookmarks = async ({ text: inputText }) => {
  let text = inputText;

  const r = new RegExp(/[a-z0-9] +[\(a-z0-9]/, "gi");

  while (true) {
    let match = r.exec(text);
    if (!match) {
      break;
    }
    text = `${text.substring(0, match.index + 1)} & ${text.substring(
      match.index + 2
    )}`;
  }

  //   console.log("text:", text);
  if (!text?.length) {
    let { data, error } = await supabase
      .from("bookmarks")
      .select(
        `
            id,
            title,
            url,
            owner_id,
            is_public,
            user_ids,
            created_at,
            owner:owner_id (
                email
            )`
      )
      .limit(50)
      .order("created_at", { ascending: false });
    return data;
  }

  // Full text search
  // let { data, error } = await supabase.rpc("search_bookmarks", {
  //   keyword: text,
  // });

  // iLike queries
  // - broken with ".or" a little bit (probably breaks with commas)
  let { data, error } = await supabase
    .from("bookmarks")
    .select(
      `
            id,
            title,
            text,
            owner_id,
            is_public,
            user_ids,
            created_at,
            owner:owner_id (
                email
            )`
    )
    .or(`title.ilike."%${inputText}%",text.ilike."%${inputText}%"`)
    .limit(50)
    .order("created_at", { ascending: false });

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error("Bookmark not found");
  }

  return data;
};

export default function useBookmarksQuery({ text }, params = {}) {
  return useQuery(["bookmarks", { text }], () => getBookmarks({ text }), {
    keepPreviousData: true,
    ...params,
  });
}
