// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import { supabase } from "../lib/api";
import Tree, { TreeNode } from "rc-tree";
import {
  FolderIcon,
  FolderOpenIcon,
  FolderRemoveIcon,
  CollectionIcon,
  QuestionMarkCircleIcon,
  LockClosedIcon,
  LockOpenIcon,
  RefreshIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";
import { cx, css } from "@emotion/css";

import { useSearch, useNavigate } from "@tanstack/react-location";

import {
  useThrottle,
  useDebouncedValue,
  useCountdown,
  useMapState,
} from "rooks";

import CopyLink from "../lib/CopyLink";

import useAppStore from "../store/app";

// @ts-ignore
import KeyboardEventHandler from "react-keyboard-event-handler";

import useSingleQuery from "../hooks/useSingleQuery";
import useExistsQuery from "../hooks/useExistsQuery";
import { useFileLinkQuery, useFileLinkMutation } from "../hooks/useFileLink";
import useUserQuery from "../hooks/useUserQuery";
import useFileUpdate from "../hooks/useFileUpdate";
import useRealtime from "../hooks/useRealtime";
import PermissionsHeader from "./PermissionsHeader";

const ViewFile = ({ user }) => {
  const [status, setStatus] = useState("view"); // view / edit
  const [viewStatus, setViewStatus] = useState("view"); // view / markdown
  const [editStatus, setEditStatus] = useState("edit"); // edit / preview

  const [title, setTitle] = useState("");
  const [autosaveNum, setAutosaveNum] = useState(0);
  const [debouncedValue, immediatelyUpdateDebouncedValue] = useDebouncedValue(
    autosaveNum,
    1500
  );
  // const realSaveNum = useThrottle(autosaveNum, 3000); // should use debounce instead?
  const waitingToSave = autosaveNum !== debouncedValue ? true : false; // autosaveNum !== realSaveNum ? true : false;

  // autosave
  useEffect(() => {
    if (status === "edit" && debouncedValue) {
      // console.log("SAVE UPDATED CONTENT", autosaveNum);
      // console.log("autosave", autosaveNum);
      handleSave();
    }
  }, [status, debouncedValue]);
  // console.log("num", autosaveNum, debouncedValue);

  const setCurrentlyViewing = useAppStore((s) => s.setCurrentlyViewing);
  const search = useSearch();
  const navigate = useNavigate();

  const {
    data: file,
    isLoading: isLoadingFile,
    isError: isErrorFile,
    refetch: refetchFile,
  } = useSingleQuery({
    table: "files",
    id: search.id,
    select: `
            *,
            owned_by:owner (
                email
            )`,
  });

  const {
    data: existsObj,
    isLoading: isLoadingExistsObj,
    isError: isErrorExistsObj,
    refetch: refetchExistsObj,
  } = useExistsQuery({
    table: "files",
    pk: search.id,
  });

  // const fileLinkMutatation = useFileLinkMutation();
  // useEffect(() => {
  //   if (file) {
  //     fileLinkMutatation.mutateAsync({ path: file.name });
  //   }
  // }, [file?.name]);

  const onUpdateFile = React.useCallback((newFile) => {
    // setFile(newFile)
    refetchFile();
  }, []);

  const { presenceState, channel, setPresence } = useRealtime({
    room: search.id,
    user,
    // id: search.id,
    // table: "files",
    // onUpdate: onUpdateFile,
  });
  // console.log("presenceState:", presenceState);
  const presenceIsCurrentlyEditing = isEditing(presenceState);
  // console.log("presenceIsCurrentlyEditing:", presenceIsCurrentlyEditing);

  useEffect(() => {
    if (file) {
      // && status !== "edit") {
      setTitle(file.title);
      setCurrentlyViewing({ title: file.title, type: "file", id: file.id });
      // document.title = file.title;
    }
    return () => {
      setCurrentlyViewing(null);
    };
  }, [file?.title]);

  const updateFile = useFileUpdate();

  const handleTogglePublic = async () => {
    await updateFile.mutateAsync({ id: file.id, is_public: !file.is_public });
    refetchFile();
  };
  const handleSave = async () => {
    if (status !== "edit") {
      // console.log("status not edit");
      return;
    }
    // console.log("do save");

    // TODO: have a "last saved" value, dont save if we don't need to (ie, if I deleted all the stuff I just typed)

    await updateFile.mutateAsync({
      id: file.id,
      title: title,
    });
    refetchFile();
  };
  const saveAndExitEdit = async () => {
    handleSave();
    leaveEdit();
  };
  const leaveEdit = () => {
    setPresence({ editing: false });
    setStatus("view");
  };
  useEffect(() => {
    if (
      status === "edit" &&
      presenceIsCurrentlyEditing?.length &&
      presenceIsCurrentlyEditing?.[0]?.user !== user.id
    ) {
      alert("Sorry, being kicked because you are no longer the editor");
      leaveEdit();
    }
  }, [status, presenceIsCurrentlyEditing, user]);

  const handleStartEdit = () => {
    // console.log("presenceIsCurrentlyEditing", presenceIsCurrentlyEditing);
    if (presenceIsCurrentlyEditing?.length) {
      alert("Unable to start editing while somebody else already is");
      return;
    }
    setStatus("edit");
    setEditStatus("edit");
  };
  useEffect(() => {
    setPresence({ editing: status === "edit" ? true : false });
  }, [status]);

  useEffect(() => {
    if (
      status !== "edit" && // editing
      file && // file exists
      !file.title?.length &&
      file.owner === user.id
    ) {
      // no title or text
      handleStartEdit();
      // navigate({
      //   search: (old) => ({
      //     ...old,
      //     edit: undefined,
      //   }),
      //   replace: true,
      // });
    }
  }, [status, file]);

  const handleKeyEvent = (key: any, e: any) => {
    //   if (!canEdit) return;

    switch (key) {
      case "ctrl+s":
      case "meta+s":
      case "cmd+s":
        handleSave();
        e.preventDefault();
        break;
      default:
        break;
    }
  };

  // console.log("status:", status);
  // console.log("viewStatus:", viewStatus);
  // console.log("editStatus:", editStatus);

  if (!file && isLoadingFile) {
    return (
      <div className="h-full flex items-center justify-center text-center">
        <div>
          <LoadingIcon />
          <div className="mt-2 text-sm text-gray-400">Loading File</div>
        </div>
      </div>
    );
  }

  if (!file && isErrorFile) {
    if (existsObj?.data) {
      return (
        <div className="h-full flex items-center justify-center text-center">
          <div>
            <ExclamationIcon className="h-5 w-5 inline-block text-orange-500" />
            <div className="mt-2 text-sm text-orange-500">
              You do not have the necessary permissions to view this file.
              <br />
              Contact the file owner for access:{" "}
              <span className="font-bold">
                <Owner
                  id={existsObj.data.owner_id}
                  owner={existsObj.data.owner}
                />
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="h-full flex items-center justify-center text-center">
          <div>
            <ExclamationIcon className="h-5 w-5 inline-block text-red-400" />
            <div className="mt-2 text-sm text-red-400">Error loading file</div>
          </div>
        </div>
      );
    }
  }

  let filename = file?.name?.split("/") ?? [];
  filename = filename.length ? filename[filename.length - 1] : "";
  // console.log("filename:", filename);

  return (
    <>
      <KeyboardEventHandler
        handleKeys={["meta+s", "ctrl+s", "meta"]}
        onKeyEvent={handleKeyEvent}
        handleFocusableElements
      />
      <div className="border-b bg-white">
        <div className="flex items-center space-x-1 px-1 py-1">
          <div className="">
            <PermissionsHeader
              obj={file}
              ownerField={"owner"}
              user={user}
              updateObj={updateFile}
              refetch={refetchFile}
            />
          </div>
          <div className="text-sm">
            <Owner id={file.owner} owner={file.owned_by} />
          </div>
          <div className="pl-2">
            <CopyLink link={`/?type=file&id=${file.id}`} />
          </div>
          <div className="flex-auto"></div>
          {presenceIsCurrentlyEditing?.length ? (
            <div className="text-green-500 text-sm">
              Editing:{" "}
              <CurrentEditor user_id={presenceIsCurrentlyEditing[0].user} />
            </div>
          ) : null}
          {status === "edit" ? (
            <div className="">
              <span
                type="span"
                // className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-sm  "
                className="inline-flex justify-center rounded-md border border-transparent px-2 py-1 text-sm  text-gray-400 "
              >
                {updateFile.isLoading || waitingToSave ? "Saving..." : "Saved"}
              </span>
            </div>
          ) : null}
          {status === "view" ? (
            <>
              <div className="">
                <button
                  onClick={handleStartEdit}
                  className={`inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2`}
                >
                  Edit
                </button>
              </div>
            </>
          ) : null}
          {status === "edit" ? (
            <>
              <div className="">
                <button
                  onClick={saveAndExitEdit}
                  className={`inline-flex justify-center rounded-md border border-transparent bg-green-100 px-2 py-1 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2`}
                >
                  Done Editing
                </button>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div
        className="border-b"
        onDoubleClick={(event) => {
          if (status !== "edit") {
            handleStartEdit();
            setTimeout(() => {
              event.target.focus();
            }, 100);
          }
        }}
      >
        <input
          disabled={status === "view" ? true : false}
          className="w-full text-xl px-2 py-1 outline-none bg-white"
          onChange={(e) => {
            setTitle(e.target.value);
            // throttledFunction(realSaveNum + 1);
            setAutosaveNum((v) => v + 1);
          }}
          onKeyDown={(event) => {
            // on enter, save and exit editing
          }}
          value={title}
          placeholder="Untitled"
        />
      </div>
      <div className="h-full overflow-y-auto px-2 py-1 bg-white flex flex-col">
        {/* <div className={``}> */}
        {/* View */}
        {/* <div className="mt-2">
            Download as:{" "}
            <a
              href="#"
              className="text-blue-600 hover:text-blue-500 underline hover:underline"
            >
              {filename}
            </a>
          </div>
          <div className="mt-2">
            <a
              href="#"
              className="text-blue-600 hover:text-blue-500 underline hover:underline"
            >
              Get Signed URL (with expiration)
            </a>
          </div> */}

        <div className="mt-2 flex space-x-3">
          <div className={`markdown-body`}>
            <pre>
              <code>{JSON.stringify(file?.metadata, null, 2)}</code>
            </pre>
          </div>
          <div className="mt-2">
            <DownloadLink file={file} />
          </div>
        </div>
        <div className="h-full mt-2">
          <RenderFilePreview file={file} />
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

const DownloadLink = ({ file }) => {
  const [
    map,
    { set, setMultiple, has, remove, removeMultiple, removeAll },
  ] = useMapState({
    status: "none",
    endTime: new Date(),
    timeout: 60,
    link: "",
  });

  const onEnd = () => {
    if (map.status === "countdown") {
      set("status", "done");
    }
  };

  const count = useCountdown(map.endTime, {
    interval: 1000,
    // onDown: (time) => console.log("onDown", time),
    onEnd,
  });

  const fileLinkMutatation = useFileLinkMutation();

  const handleCreateLink = async () => {
    set("status", "creating");
    const { signedUrl, expires } = await fileLinkMutatation.mutateAsync({
      path: file.name,
      timeout: 60,
    });
    console.log("expires:", expires);
    setMultiple({
      status: "countdown",
      endTime: new Date(expires),
      link: signedUrl,
    });
  };
  const handleCreateLinkTimeout = async () => {
    let newTime = window.prompt("Expiration in seconds:", map.timeout);
    if (!newTime) {
      return false;
    }

    newTime = parseInt(newTime, 10);
    set("timeout", newTime);

    set("status", "creating");

    const { signedUrl, expires } = await fileLinkMutatation.mutateAsync({
      path: file.name,
      timeout: newTime,
    });

    setMultiple({
      status: "countdown",
      endTime: new Date(expires),
      link: signedUrl,
    });
  };

  switch (map.status) {
    case "none":
      return (
        <div className="">
          <a
            className="underline text-blue-500 hover:text-blue-400 cursor-pointer"
            onClick={handleCreateLink}
          >
            Create a link
          </a>{" "}
          (
          <a
            className="underline text-blue-500 hover:text-blue-400 cursor-pointer"
            onClick={handleCreateLinkTimeout}
          >
            {map.timeout}s
          </a>
          )
        </div>
      );
    case "creating":
      return <div className="">Creating link...</div>;
    case "countdown":
      return (
        <div className="">
          <div className="mb-1">
            <a
              href={map.link}
              className="underline text-blue-500 hover:text-blue-400"
              target="_blank"
            >
              Link (opens in new window)
            </a>
          </div>
          <span className="text-xs font-medium inline-block py-0.5 px-1 rounded-full text-cyan-600 bg-cyan-200 last:mr-0 ml-1 mr-1">
            Expires: {count}s
          </span>
        </div>
      );
    case "done":
    default:
      return (
        <div className="">
          <div className="mb-1">
            <a
              className="underline text-blue-500 hover:text-blue-400 cursor-pointer"
              onClick={handleCreateLink}
            >
              Create a link
            </a>{" "}
            (
            <a
              className="underline text-blue-500 hover:text-blue-400 cursor-pointer"
              onClick={handleCreateLinkTimeout}
            >
              {map.timeout}s
            </a>
            )
          </div>
          <span className="text-xs font-medium inline-block py-0.5 px-1 rounded-full text-orange-600 bg-orange-200 last:mr-0 ml-1 mr-1">
            Link Expired
          </span>
        </div>
      );
  }
  return count;

  return null;
};

const RenderFilePreview = ({ file }) => {
  // render file preview depending on type of file
  let PreviewComponent = () => null;

  const { data: fileLinkInfo } = useFileLinkQuery({ path: file.name });
  // console.log("fileLinkInfo:", fileLinkInfo);
  // useEffect(() => {
  //   if (file) {
  //     fileLinkMutatation.mutateAsync({ path: file.name });
  //   }
  // }, [file?.name]);
  // useEffect(() => {

  // }, [fileLinkInfo]);

  switch (file.metadata.mimetype?.split("/")[0]) {
    case "image":
      PreviewComponent = PreviewImage;
      break;
    case "video":
      PreviewComponent = PreviewVideo;
      break;
    case "application":
      switch (file.metadata.mimetype?.split("/")[1]) {
        case "pdf":
          PreviewComponent = PreviewInIFrame;
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }

  const link = fileLinkInfo?.signedUrl;

  if (!link) {
    return null;
  }

  return <PreviewComponent file={file} link={link} />;
};

const PreviewImage = ({ file, link }) => {
  return <img className="max-w-full" src={link} />;
};

const PreviewInIFrame = ({ file, link }) => {
  return <iframe className="w-full max-w-full h-full" src={link} />;
};

const PreviewVideo = ({ file, link }) => {
  return (
    <video className="max-w-full" controls>
      <source src={link} type={file.metadata.mimetype} />
    </video>
  );
};

const LoadingIcon = () => {
  return (
    <svg
      className="animate-spin h-5 w-5 inline-block text-gray-400"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

const Owner = ({ id, owner }) => {
  const { data: user } = useUserQuery({ id: owner ? null : id });
  return owner?.email?.split("@")[0] ?? user?.email?.split("@")[0] ?? "";
};

const CurrentEditor = ({ user_id }) => {
  const { data: user } = useUserQuery({ id: user_id });
  // console.log("info", { user, user_id });
  return `${user?.first_name} ${user?.last_name}`;
};

const isEditing = (presenceState) => {
  return Object.keys(presenceState)
    .map((k) => presenceState[k][0])
    .filter((v) => v.editing);
};

// const useRealtime = () => {
//     // useEffect(() => {
//     //     const channel = supabase.channel("online-users");

//     //     function getRandomUser() {
//     //         const users = ["Alice", "Bob", "Mallory", "Inian"];
//     //         return users[Math.floor(Math.random() * users.length)];
//     //     }

//     //     channel
//     //         .on("presence", { event: "sync" }, () => {
//     //             console.log("currently online users", channel.presenceState());
//     //         })
//     //         .on("presence", { event: "join" }, ({ newUser }) => {
//     //             console.log("a new user has joined", newUser);
//     //         })
//     //         .on("presence", { event: "leave" }, ({ leftUser }) =>
//     //             console.log("a user has left", leftUser)
//     //         )
//     //         .subscribe(async (status) => {
//     //             if (status === "SUBSCRIBED") {
//     //                 const status = await channel.track({
//     //                     user_name: getRandomUser(),
//     //                 });
//     //                 console.log("sub status:", status);
//     //             }
//     //         });

//     //     return () => {
//     //         // channel.leave();
//     //     };
//     // }, []);
//     return null;
// };

export default ViewFile;
