import { useQuery } from "react-query";
import { supabase } from "../lib/api";

const getSearchResultsILike = async ({
  table,
  text,
  field = "title",
  select = `*`,
}) => {
  let { data, error } = await supabase
    .from(table)
    .select(select)
    .ilike(field, `%${text}%`)
    .limit(50)
    .order("created_at", { ascending: false });

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error("Multiple not found");
  }

  // console.log("MULTIPLE:", data);

  return data;
};

export default function useSearchTableQuery(
  { table, text, field, select },
  params = {}
) {
  return useQuery(
    [table, { field, text, select }],
    () => getSearchResultsILike({ table, text, field, select }),
    {
      ...params,
    }
  );
}
