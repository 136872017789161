// @ts-nocheck
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect, useRef } from "react";

import useQueryDoc from "../hooks/useQueryDoc";

function CollectionItemDialogWrapper({ item, onComplete }) {
  if (!item) {
    return null;
  }
  return <CollectionItemDialog {...{ item, onComplete }} />;
}

const CollectionItemDialog = ({ item, onComplete }) => {
  const isOpen = true;

  const { data: doc, isLoading, isLoadingError } = useQueryDoc({
    id: item.type === "doc" ? item.id : null,
  });

  const [state, setState] = useState({ ...item });

  function closeModal() {
    onComplete(null);
  }

  function openModal() {
    // setIsOpen(true);
  }

  // force autofocus
  const inputRef = useRef();
  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 150);
  }, []);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Edit Item
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    <input
                      ref={inputRef}
                      // autoFocus // does not work for some reason (a flicker when rendering the Dialog?)
                      // onFocus={(e) =>
                      //     console.log("Focusing")
                      // }
                      className="w-full border rounded p-4"
                      onChange={(e) =>
                        setState({
                          ...state,
                          title: e.target.value,
                        })
                      }
                      value={state.title}
                    />
                  </p>
                </div>
                {item.type === "url" ? (
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      <input
                        className="w-full border rounded p-4"
                        onChange={(e) =>
                          setState({
                            ...state,
                            url: e.target.value,
                          })
                        }
                        value={state.url}
                        placeholder="URL"
                      />
                    </p>
                  </div>
                ) : null}

                {doc ? (
                  <div className="mt-4">
                    <p className="text-sm text-gray-500">
                      Doc Title: {doc.title}
                    </p>
                  </div>
                ) : null}
                {isLoading ? (
                  <div className="mt-4">
                    <p className="text-sm text-gray-500">Loading doc</p>
                  </div>
                ) : null}
                {isLoadingError ? (
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">Unable to load doc</p>
                  </div>
                ) : null}

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-blue-900 hover:bg-red-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 mr-4"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={(e) => onComplete(state)}
                  >
                    Save Changes
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CollectionItemDialogWrapper;
