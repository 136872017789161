// @ts-nocheck
import React, { useEffect, useState, useRef, Fragment } from "react";
import { supabase } from "../lib/api";
import { v4 as uuidv4 } from "uuid";

export const useRealtime = ({
  room,
  user,
  id,
  table,
  idKey = "id",
  onUpdate,
}) => {
  const [presenceState, setPresenceState] = useState([]);
  const [channel, setChannel] = useState();

  useEffect(() => {
    if (room?.length) {
      const channel2 = supabase
        .channel(`${room}`) // note we CANNOT have 2/duplicates of these, otherwise the "presence" may not get updated on 1/both listeners!
        // .on("presence", { event: "join" }, (payload) => {
        //     console.log("join payload:", payload);
        //     const idsToAdd = payload.newPresences.map((p) => p.user);

        //     setOnlineUsers((prev) =>
        //         Array.from(new Set([...prev, ...idsToAdd]))
        //     );
        // })
        .on("presence", { event: "sync" }, (payload) => {
          // console.log("sync payload:", payload);
          // const idsToRemove = payload.leftPresences.map((p) => p.user );

          const newState = channel2.presenceState();
          // console.log("newState:", typeof newState, newState);
          setPresenceState({ ...newState });

          // setOnlineUsers((prev) =>
          //     Array.from(
          //         new Set(prev.filter((id) => idsToRemove.includes(id) === false))
          //     )
          // );
        })
        // .on("presence", { event: "leave" }, (payload) => {
        //     console.log("leave payload:", payload);
        //     const idsToRemove = payload.leftPresences.map((p) => p.user);

        //     setOnlineUsers((prev) =>
        //         Array.from(
        //             new Set(
        //                 prev.filter(
        //                     (id) => idsToRemove.includes(id) === false
        //                 )
        //             )
        //         )
        //     );
        // })
        .subscribe(async (status: "SUBSCRIBED") => {
          if (status === "SUBSCRIBED") {
            // if (user) {
            // await channel.track({ user: user.id });
            // }
          }
        });

      setChannel(channel2);
      return () => {
        channel2.unsubscribe();
        setChannel(null);
      };
    }
  }, [room]);

  const setPresence = async (status) => {
    if (channel) {
      await channel.track({ user: user.id, ...status });
    }
  };

  // useEffect(() => {
  //   if (id?.length) {
  //     const databaseFilter = {
  //       schema: "public",
  //       table: table,
  //       filter: `${idKey}=eq.${id}`,
  //       event: "UPDATE",
  //     };

  //     // TODO: this will error if there are two useRealtime that subscribe to the same set of data!!!
  //     // - we need to ensure the "channel" is not already being watched, and if it is, attach to that listener!!
  //     //   - what matters? the "channel" or the "databaseFilter" ??
  //     const channelName = `watch:${table}:${id}`;
  //     const channel3 = supabase
  //       .channel(uuidv4()) // cannot have a duplicate channelName! otherwise it is ignored!??
  //       .on("postgres_changes", databaseFilter, (payload) =>
  //         receivedDatabaseEvent(payload)
  //       )
  //       .subscribe();
  //     // console.log("Subscribed to REALTIME:", channelName, idKey, id);

  //     const receivedDatabaseEvent = (event) => {
  //       const { payload } = event;
  //       // console.log("database event:", event);
  //       onUpdate && onUpdate(event.new);
  //     };
  //     return () => {
  //       channel3.unsubscribe();
  //       // console.log("Unsubscribed to:", channelName);
  //     };
  //   }
  // }, [id, table, idKey, onUpdate]);

  // console.log("onlineUsers", onlineUsers);
  return { presenceState, channel, setPresence };
};

export default useRealtime;
