import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../lib/api";

import { v4 as uuidv4 } from "uuid";
import { resolve } from "dns";
import { rejects } from "assert";

// @ts-ignore
const uploadFile = async (vars) => {
  const fileObj = vars.file;
  const folder = uuidv4();
  // const { data, error } = await supabase.storage
  //   .from("private-files")
  //   .upload(`${folder}/${fileObj.name}`, fileObj, {
  //     cacheControl: "1", // 1 second, not long because we want auth to be checked frequently
  //     upsert: false, // dont allow overwrite
  //   });
  // if (error) {
  //   throw new Error("Failed uploading file");
  // }
  // console.log("data:", data);
  // const { path } = data;
  // console.log("Path:", path);
  // return { path, filename: fileObj.name, folder };

  // @ts-ignore
  const supabase_access_token = await supabase._getAccessToken();

  const bucket = "private-files";
  const path = `${folder}/${fileObj.name}`;

  // Typescript will give errors for accessing protected members of supabase
  // @ts-ignore
  const url = `${supabase.supabaseUrl}/storage/v1/object/${bucket}/${path}`;
  // @ts-ignore
  // const headers = supabase.auth.headers;

  const formData = new FormData();
  formData.append("cacheControl", "1");
  formData.append("", fileObj, fileObj.name);

  const req = new XMLHttpRequest();
  req.upload.onprogress = vars.setProgress;
  req.upload.onabort = vars.onError;
  req.upload.onerror = vars.onError;
  req.upload.ontimeout = vars.onError;
  req.open("POST", url);
  // for (const [key, value] of Object.entries(headers)) {
  //   // @ts-ignore
  //   req.setRequestHeader(key, value);
  // }
  req.setRequestHeader("authorization", `Bearer ${supabase_access_token}`);
  req.setRequestHeader("x-upsert", "false"); // prevent upsert (this is a supabase thing)
  // @ts-ignore
  req.send(formData);

  return new Promise((resolve, reject) => {
    // req.upload.onload = vars.onUploadComplete;
    // req.upload.onload = transferComplete;

    req.onreadystatechange = function (aEvt) {
      if (req.readyState == 4) {
        if (req.status == 200) {
          console.log("Successfully uploaded!");
          resolve({ path, filename: fileObj.name, folder });
          // alert("Error loading page\n");
        } else {
          console.error("Failed uploading");
          reject();
        }
      }
    };
  });
};

export default function useUploadFile() {
  return useMutation(
    // @ts-ignore
    ({ file, setProgress, onUploadComplete }) =>
      uploadFile({ file, setProgress, onUploadComplete }),
    {}
  );
}
