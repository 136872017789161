// @ts-nocheck
import React, { useEffect, useState, useRef, useCallback } from "react";
import { supabase } from "../lib/api";
import Tree, { TreeNode } from "rc-tree";
import {
  FolderIcon,
  FolderOpenIcon,
  FolderRemoveIcon,
  CollectionIcon,
  DocumentTextIcon,
  QuestionMarkCircleIcon,
  LockClosedIcon,
  LockOpenIcon,
  RefreshIcon,
  ExclamationIcon,
  ShareIcon,
  GlobeAltIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import { cx, css } from "@emotion/css";

import { useEditor, EditorContent } from "@tiptap/react";

import useResizeObserver from "@react-hook/resize-observer";
// import useResizeAware from "react-resize-aware";
import { useSearch, useNavigate, Link } from "@tanstack/react-location";

import {
  useThrottle,
  useDebouncedValue,
  useRefElement,
  useMergeRefs,
  useDimensionsRef,
  useDidMount,
  useDidUpdate,
} from "rooks";
import { useHash, useMount } from "react-use";

import CopyLink from "../lib/CopyLink";

import useAppStore from "../store/app";

// @ts-ignore
import KeyboardEventHandler from "react-keyboard-event-handler";

import useSingleQuery from "../hooks/useSingleQuery";
import useExistsQuery from "../hooks/useExistsQuery";
import useQueryDoc from "../hooks/useQueryDoc";
import useUserQuery from "../hooks/useUserQuery";
import useDocUpdate from "../hooks/useDocUpdate";

import PermissionsHeader from "./PermissionsHeader";

import useRealtime from "../hooks/useRealtime";

import { useOurEditor, RenderEditor } from "./tiptap/Editor/Editor";
import { MenuBar } from "./tiptap/Editor/MenuBar";

let lastHash;

const ViewDoc = ({ user }) => {
  const [isSynced, setSynced] = useState(false); // view / edit
  const [status, setStatus] = useState("edit"); // view / edit
  const [viewStatus, setViewStatus] = useState("view"); // view / markdown
  const [editStatus, setEditStatus] = useState("edit"); // edit / preview
  const [title, setTitle] = useState("");

  const [autosaveNum, setAutosaveNum] = useState(0);
  const [debouncedValue, immediatelyUpdateDebouncedValue] = useDebouncedValue(
    autosaveNum,
    1500
  );
  const waitingToSave = autosaveNum !== debouncedValue ? true : false; // autosaveNum !== realSaveNum ? true : false;

  useEffect(() => {
    if (status === "edit" && debouncedValue) {
      handleSave();
    }
  }, [status, debouncedValue]);

  const setCurrentlyViewing = useAppStore((s) => s.setCurrentlyViewing);
  const search = useSearch();
  const navigate = useNavigate();

  const {
    data: doc,
    isLoading: isLoadingDoc,
    isError: isErrorDoc,
    refetch: refetchDoc,
  } = useSingleQuery({
    table: "docs",
    id: search.id,
    select: `
      id,
      title,
      text,
      owner_id,
      is_public,
      user_ids,
      created_at,
      owner:owner_id (
          email
      )`,
  });

  // cannot use graphql in views: https://github.com/supabase/pg_graphql/issues/205
  // - if we wanted to search table:docs_no_data

  const {
    data: existsObj,
    isLoading: isLoadingExistsObj,
    isError: isErrorExistsObj,
    refetch: refetchExistsObj,
  } = useExistsQuery({
    table: "docs",
    pk: search.id,
  });

  const { presenceState, channel, setPresence } = useRealtime({
    room: search.id,
    user,
  });
  const presenceIsCurrentlyEditing = isEditing(presenceState);

  useEffect(() => {
    if (doc) {
      setTitle(doc.title);
      setCurrentlyViewing({ title: doc.title, type: "doc", id: doc.id });
    }
    return () => {
      setCurrentlyViewing(null);
    };
  }, [doc?.title]);

  const updateDoc = useDocUpdate();

  const handleSave = async () => {
    if (status !== "edit") {
      return;
    }
    await updateDoc.mutateAsync({
      id: doc.id,
      title: title,
    });
    refetchDoc();
  };

  const leaveEdit = () => {
    setPresence({ editing: false });
    setStatus("view");
  };

  const handleStartEdit = () => {
    setStatus("edit");
    setEditStatus("edit");
  };

  useEffect(() => {
    if (
      status !== "edit" && // editing
      doc && // doc exists
      !doc.text?.length && // no title or text
      !doc.title?.length &&
      doc.owner_id === user.id
    ) {
      handleStartEdit();
    }
  }, [status, doc]);

  const handleKeyEvent = (key: any, e: any) => {
    //   if (!canEdit) return;
    switch (key) {
      case "ctrl+s":
      case "meta+s":
      case "cmd+s":
        handleSave();
        e.preventDefault();
        break;
      default:
        break;
    }
  };

  const onSynced = useCallback(() => {
    setSynced(true);
  }, []);

  const handleEditorUpdate = (editor) => {
    // console.log("updated editor");
    buildToc(editor);
  };

  const editor = useOurEditor({
    status,
    id: search.id,
    user,
    onSynced,
    onUpdate: handleEditorUpdate,
  });

  const [hash, setHash] = useHash();
  // console.log("hash:", hash);
  const [toc, setToc] = useState(null);
  // const [resizeListener, sizes] = useResizeAware();
  // const [scrollContainerRef1, dimensions, node] = useDimensionsRef();
  const fixedHeightContainer = useRef(null);
  const scrollContainerRef0 = useRef(null);
  const scrollContainerRef1 = useRef(null);
  const [scrollContainerRef2, scrollContainerElement] = useRefElement();
  const scrollContainerRef = useMergeRefs(
    scrollContainerRef1,
    scrollContainerRef2
  );

  const [height, setHeight] = useState(10);

  const size = useSize(scrollContainerRef1);

  const updateHeight = () => {
    const v = fixedHeightContainer.current?.getBoundingClientRect();
    if (v) {
      const newheight = window.innerHeight - v.top;
      if (height !== newheight) {
        setHeight(newheight);
      }
    }
  };
  useDidUpdate(() => {
    updateHeight();
  });

  useEffect(() => {
    // console.log("upated sizes");
    buildToc(editor);
    updateHeight();
  }, [size?.width, size?.height, editor]);

  // console.log("toc:", { toc, size });
  const buildToc = (editor) => {
    if (!editor) {
      return null;
    }
    // setToc(mdRef?.current ? generateLinkMarkup(mdRef.current) : []);
    // console.log("build toc");
    // console.log("editor.json", editor.getJSON());
    const headings = [];
    let minDepth = 6;
    function getText(item) {
      let text = "";
      if (item.type === "text") {
        text = `${text}${item.text}`;
      } else {
        if (item.content) {
          for (let i2 of item.content) {
            const t2 = getText(i2);
            text = `${text}${t2}`;
          }
        }
      }
      return text;
    }
    function check(item) {
      const skipItems = ["details"];
      if (item.type === "heading") {
        // get text for heading
        let text = getText(item);
        headings.push({
          id: text,
          title: text,
          depth: item.attrs.level,
        });
        minDepth = minDepth < item.attrs.level ? minDepth : item.attrs.level;
      } else {
        if (!skipItems.includes(item.type)) {
          if (item.content) {
            for (let i2 of item.content) {
              check(i2);
            }
          }
        }
      }
    }
    check(editor.getJSON());
    setToc({ minDepth, headings });
  };
  useEffect(() => {
    if (
      hash?.length > 1 &&
      hash !== lastHash &&
      isSynced &&
      toc !== null &&
      !lastHash
    ) {
      lastHash = hash;
      let item = toc?.headings?.find((i) => `#${i.id}` === hash);
      // console.log("hash updated:", {
      //   hash,
      //   item,
      //   scrollContainerRef,
      //   scrollContainerElement,
      // });
      if (item) {
        setTimeout(() => {
          scrollContainerElement.scrollTo(0, item.top);
          // console.log("scrolled");
        }, 1);
      }
    }
  }, [hash, isSynced, JSON.stringify(toc)]);

  useEffect(() => {
    if (editor && scrollContainerElement && isSynced) {
      // console.log("have element now", scrollContainerElement);
      // setTimeout(buildToc, 1);
      buildToc(editor);
    }
  }, [scrollContainerElement, isSynced, editor]);

  if ((!doc && isLoadingDoc) || (!existsObj?.data && isLoadingExistsObj)) {
    return (
      <div className="h-full flex items-center justify-center text-center">
        <div>
          <LoadingIcon />
          <div className="mt-2 text-sm text-gray-400">Loading Document</div>
        </div>
      </div>
    );
  }

  if (!doc && isErrorDoc) {
    // just hidden to user, or does not actually exist?
    if (existsObj?.data) {
      return (
        <div className="h-full flex items-center justify-center text-center">
          <div>
            <ExclamationIcon className="h-5 w-5 inline-block text-orange-500" />
            <div className="mt-2 text-sm text-orange-500">
              You do not have the necessary permissions to view this document.
              <br />
              Contact the document owner for access:{" "}
              <span className="font-bold">
                <Owner
                  id={existsObj.data.owner_id}
                  owner={existsObj.data.owner}
                />
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="h-full flex items-center justify-center text-center">
          <div>
            <ExclamationIcon className="h-5 w-5 inline-block text-red-400" />
            <div className="mt-2 text-sm text-red-400">
              Error loading document
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <>
      <KeyboardEventHandler
        handleKeys={["meta+s", "ctrl+s", "meta"]}
        onKeyEvent={handleKeyEvent}
        handleFocusableElements
      />
      <div className="border-b bg-white">
        <div className="flex items-center space-x-1 px-1 py-1">
          <div className="">
            <PermissionsHeader
              obj={doc}
              user={user}
              updateObj={updateDoc}
              refetch={refetchDoc}
            />
          </div>
          <div className="text-sm">
            <Owner id={doc.owner_id} owner={doc.owner} />
          </div>
          <div className="pl-2">
            <CopyLink link={`/?type=doc&id=${doc.id}`} />
          </div>
          <div className="flex-auto"></div>
          {/* {presenceIsCurrentlyEditing?.length ? (
            <div className="text-green-500 text-sm">
              Editing:{" "}
              <CurrentEditor user_id={presenceIsCurrentlyEditing[0].user} />
            </div>
          ) : null} */}
          {/* {status === "edit" ? (
            <div className="">
              <span
                type="span"
                // className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-sm  "
                className="inline-flex justify-center rounded-md border border-transparent px-2 py-1 text-sm  text-gray-400 "
              >
                {updateDoc.isLoading || waitingToSave ? "Saving..." : "Saved"}
              </span>
            </div>
          ) : null} */}
          {status === "view" ? (
            <>
              {/* <div className="">
                <div className="flex space-x-1 rounded-xl bg-blue-200 p-1 w-auto">
                  <button
                    onClick={() => setViewStatus("view")}
                    className={`${
                      viewStatus === "view" ? "bg-white shadow" : ""
                    } w-full rounded-lg px-2 py-0 text-xs font-medium leading-5 text-blue-700 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2`}
                  >
                    View
                  </button>
                  <button
                    onClick={() => setViewStatus("markdown")}
                    className={`${
                      viewStatus === "markdown" ? "bg-white shadow" : ""
                    } w-full rounded-lg px-2 py-0 text-xs font-medium leading-5 text-blue-700 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2`}
                  >
                    Markdown
                  </button>
                </div>
              </div> */}
              {/* <div className="">
                <button
                  onClick={handleStartEdit}
                  className={`inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2`}
                >
                  Edit
                </button>
              </div> */}
            </>
          ) : null}
          {status === "edit" ? (
            <>
              {/* <div className="">
                <div className="flex space-x-1 rounded-xl bg-blue-200 p-1 w-auto">
                  <button
                    onClick={() => setEditStatus("preview")}
                    className={`${
                      editStatus === "preview" ? "bg-white shadow" : ""
                    } w-full rounded-lg px-2 py-0 text-xs font-medium leading-5 text-blue-700 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2`}
                  >
                    Preview
                  </button>
                  <button
                    onClick={() => setEditStatus("edit")}
                    className={`${
                      editStatus === "edit" ? "bg-white shadow" : ""
                    } w-full rounded-lg px-2 py-0 text-xs font-medium leading-5 text-blue-700 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2`}
                  >
                    Edit
                  </button>
                </div>
              </div> */}
              {/* <div className="">
                <button
                  onClick={leaveEdit}
                  className={`inline-flex justify-center rounded-md border border-transparent bg-green-100 px-2 py-1 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2`}
                >
                  Done Editing
                </button>
              </div> */}
            </>
          ) : null}
        </div>
      </div>
      <div
        className="border-b"
        onDoubleClick={(event) => {
          if (status !== "edit") {
            handleStartEdit();
            setTimeout(() => {
              event.target.focus();
            }, 100);
          }
        }}
      >
        <input
          disabled={status === "view" ? true : false}
          className="w-full text-xl px-2 py-1 outline-none bg-white"
          onChange={(e) => {
            setTitle(e.target.value);
            // throttledFunction(realSaveNum + 1);
            setAutosaveNum((v) => v + 1);
          }}
          value={title}
          placeholder="Untitled"
        />
      </div>
      <div className={`flex flex-col overflow-hidden`}>
        <div ref={scrollContainerRef0} className={``}>
          <div className={`border-b`}>
            <MenuBar editor={editor} />
          </div>
        </div>
        <div
          // ref={scrollContainerRef}
          ref={fixedHeightContainer}
          className={`flex ${
            status === "edit" ? "OLD-bg-amber-50/50" : "bg-white"
          }`}
          style={{
            height,
          }}
          onDoubleClick={(event) => {
            if (status !== "edit") {
              handleStartEdit();
              setTimeout(() => {
                event.target.focus();
              }, 100);
            }
          }}
        >
          {toc?.headings?.length ? (
            <div className="flex-none w-[220px] border-r p-2 mr-2 h-full scroll-smooth overflow-y-auto ">
              Contents
              <br />
              {toc.headings.map((item, i) => {
                // console.log("item:", item.id, item.top);
                // console.log("minDepth:", toc.minDepth);
                return (
                  <div
                    key={i}
                    className="text-ellipsis overflow-hidden whitespace-nowrap "
                  >
                    <Link
                      // hash={`${item.id}`}
                      // to="."
                      // search={(s) => s}
                      onClick={(e) => {
                        setHash(item.id);
                        // scrollContainerRef.current.scrollTo(0, item.top);
                        e.preventDefault();
                        return false;
                      }}
                      className={`underline text-sm ml-${
                        (item.depth - 1) * 2 // not sure why this isn't working..
                      }`}
                      style={{
                        marginLeft: (item.depth - toc.minDepth) * 8,
                      }}
                    >
                      {item.title}
                    </Link>
                  </div>
                );
              })}
            </div>
          ) : null}
          <div className="flex-auto h-full scroll-smooth overflow-y-auto">
            {isSynced ? (
              <RenderEditor editor={editor} />
            ) : (
              "Syncing document..."
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const LoadingIcon = () => {
  return (
    <svg
      className="animate-spin h-5 w-5 inline-block text-gray-400"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

const Owner = ({ id, owner }) => {
  const { data: user } = useUserQuery({ id: owner ? null : id });
  return owner?.email?.split("@")[0] ?? user?.email?.split("@")[0] ?? "";
};

const CurrentEditor = ({ user_id }) => {
  const { data: user } = useUserQuery({ id: user_id });
  // console.log("info", { user, user_id });
  return `${user?.first_name} ${user?.last_name}`;
};

const isEditing = (presenceState) => {
  return Object.keys(presenceState)
    .map((k) => presenceState[k][0])
    .filter((v) => v.editing);
};

const useSize = (target) => {
  const [size, setSize] = React.useState();

  React.useLayoutEffect(() => {
    setSize(target.current?.getBoundingClientRect());
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};

// const useRealtime = () => {
//     // useEffect(() => {
//     //     const channel = supabase.channel("online-users");

//     //     function getRandomUser() {
//     //         const users = ["Alice", "Bob", "Mallory", "Inian"];
//     //         return users[Math.floor(Math.random() * users.length)];
//     //     }

//     //     channel
//     //         .on("presence", { event: "sync" }, () => {
//     //             console.log("currently online users", channel.presenceState());
//     //         })
//     //         .on("presence", { event: "join" }, ({ newUser }) => {
//     //             console.log("a new user has joined", newUser);
//     //         })
//     //         .on("presence", { event: "leave" }, ({ leftUser }) =>
//     //             console.log("a user has left", leftUser)
//     //         )
//     //         .subscribe(async (status) => {
//     //             if (status === "SUBSCRIBED") {
//     //                 const status = await channel.track({
//     //                     user_name: getRandomUser(),
//     //                 });
//     //                 console.log("sub status:", status);
//     //             }
//     //         });

//     //     return () => {
//     //         // channel.leave();
//     //     };
//     // }, []);
//     return null;
// };

// https://github.com/ueberdosis/tiptap/issues/775#issuecomment-762971612
function clipboardTextParser(text, context, plain) {
  const blocks = text.replace().split(/(?:\r\n?|\n)/);
  const nodes = [];

  blocks.forEach((line) => {
    let nodeJson = { type: "paragraph" };
    if (line.length > 0) {
      nodeJson.content = [{ type: "text", text: line }];
    }
    let node = Node.fromJSON(context.doc.type.schema, nodeJson);
    nodes.push(node);
  });

  const fragment = Fragment.fromArray(nodes);
  return Slice.maxOpen(fragment);
}

function generateLinkMarkup($contentElement) {
  const headings = [
    ...$contentElement.querySelectorAll("h1, h2, h3, h4, h5, h6"),
  ];
  const parsedHeadings = headings.map((heading) => {
    return {
      title: heading.innerText,
      depth: heading.nodeName.replace(/\D/g, ""),
      id: heading.getAttribute("id"),
      top: heading.offsetTop - 90,
    };
  });
  return parsedHeadings;
}

const transformTextEmptyLines = (text) => {
  // exclude code blocks (nothing else to exclude, for now)

  const putSpansInText = (text) => {
    const re = new RegExp(/(^[\t\ ]*$)/, "gim");
    while (true) {
      let match = re.exec(text);
      if (!match) break;
      text = text.replace(re, "<span></span>");
    }
    return text;
  };

  const separateIntoTextBlocks = (text) => {
    // does this line-by-line!
    let lines = text.split("\n");
    let output = [];
    let inBlock = false;
    let inSpoiler = false;
    let currentNonblock = [];
    let currentBlock = [];
    let spoilerBlock = [];
    // for (let line of lines) {
    //   if (line.startsWith("```")) {
    //     if (inBlock) {
    //       // end block
    //       inBlock = false;
    //       currentBlock = [...currentBlock, line];
    //       output.push({ parse: false, text: currentBlock.join("\n") });
    //       currentBlock = [];
    //     } else {
    //       // start block
    //       inBlock = true;
    //       currentBlock = [line];

    //       // end nonblock
    //       if (currentNonblock.length) {
    //         output.push({ parse: true, text: currentNonblock.join("\n") });
    //         currentNonblock = [];
    //       }
    //     }
    //   } else {
    //     if (inBlock) {
    //       currentBlock = [...currentBlock, line];
    //     } else {
    //       if (line.trim() == "") {
    //         currentNonblock = [...currentNonblock, "--empty line--"];
    //       } else {
    //         currentNonblock = [...currentNonblock, line];
    //       }
    //       // if (line.startsWith("<")) {
    //       //   if (currentNonblock.length) {
    //       //     output.push({ parse: true, text: currentNonblock.join("\n") });
    //       //     currentNonblock = [];
    //       //   }
    //       //   output.push({ parse: false, text: line });
    //       // } else {
    //       //   currentNonblock = [...currentNonblock, line];
    //       // }
    //     }
    //   }
    // }
    // if (currentBlock.length) {
    //   // parse un-closed "```" as normal
    //   output.push({ parse: true, text: currentBlock.join("\n") });
    // }
    // if (currentNonblock.length) {
    //   output.push({ parse: true, text: currentNonblock.join("\n") });
    // }

    let prevLine, ignoreNext;
    for (let line of lines) {
      if (line.startsWith("```")) {
        if (inBlock) {
          // end block
          inBlock = false;
        } else {
          // start block
          inBlock = true;
        }
        output.push(line);
      } else if (line.startsWith("!spoiler")) {
        if (inSpoiler) {
          // end spoiler
          inSpoiler = false;
          output.push("\n</details>");
        } else {
          // start spoiler
          inSpoiler = true;
          output.push(
            `\n<details><summary>${
              line.split("!spoiler")[1] ?? "Click to show/hide"
            }</summary>\n`
          );
        }
      } else {
        if (inBlock) {
          output.push(line);
        } else {
          if (line.trim().startsWith("<")) {
            // ignore next, restore previous
            // console.log("ignore line:", line);
            output.pop();
            output.push(prevLine);
            ignoreNext = true;
          }
          if (line.trim() == "") {
            if (ignoreNext) {
              ignoreNext = false;
              output.push(line);
              prevLine = line;
            } else {
              // output.push("<span></span>"); // fix this?
              // output.push("");
              output.push(line);
            }
          } else {
            output.push(line);
          }
        }
      }
      prevLine = line;
    }

    return output;
  };

  let output = "";
  // get array of items
  // - [parse, do_not_parse]
  // merge back together with newlines
  const arr = separateIntoTextBlocks(text);
  for (let part of arr) {
    if (part.parse) {
      // console.log("parse:", part.text);
      // part.text = putSpansInText(part.text);
    }
  }
  // output = arr.map((v) => v.text).join("\n");
  output = arr.join("\n");

  // console.log("FINAL OUTPUT", output);
  return output;
};

export default ViewDoc;
