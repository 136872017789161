// @ts-nocheck
import React, { useEffect, useState, useRef, Fragment } from "react";
import { supabase } from "../lib/api";
import Tree, { TreeNode } from "rc-tree";
import {
  FolderIcon,
  FolderOpenIcon,
  FolderRemoveIcon,
  CollectionIcon,
  QuestionMarkCircleIcon,
  SearchCircleIcon,
  LockOpenIcon,
  LockClosedIcon,
  PlusSmIcon,
  ExclamationIcon,
  ShareIcon,
  GlobeAltIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import { cx, css } from "@emotion/css";

import { useThrottle } from "rooks";

import { useSearch, useNavigate } from "@tanstack/react-location";
import useUploadFile from "../hooks/useUploadFile";
import useUploadFileProgress from "../hooks/useUploadFileProgress";
import useCreateDoc from "../hooks/useCreateDoc";
import useBookmarkCreate from "../hooks/useBookmarkCreate";
import useCreate from "../hooks/useCreate";
import useDocsQuery from "../hooks/useDocsQuery";
import useBookmarksQuery from "../hooks/useBookmarksQuery";
import useFilesQuery from "../hooks/useFilesQuery";
import useSearchTableQuery from "../hooks/useSearchTableQuery";
import useUserQuery from "../hooks/useUserQuery";

import PermissionIcon from "./PermissionIcon";

import Sidebar from "./Sidebar";

import toast from "react-hot-toast";
import { Link } from "@tanstack/react-location";
import { Tab, Menu, Transition } from "@headlessui/react";

import CopySearchLink from "../lib/CopySearchLink";

import useAppStore from "../store/app";
import useRealtimeSingle from "../hooks/useRealtimeSingle";

const Search = ({ user }) => {
  const navigate = useNavigate();

  const search = useSearch();
  const [searchText, setSearchText] = useState(search.search ?? "");
  const debouncedSearchText = useDebounce(searchText, 200);
  const filesQuery = useFilesQuery({
    text: debouncedSearchText,
  });
  const docsQuery = useDocsQuery({
    text: debouncedSearchText,
  });
  const bookmarksQuery = useBookmarksQuery({
    text: debouncedSearchText,
  });
  const collectionsQuery = useSearchTableQuery({
    table: "collections",
    field: "name",
    text: debouncedSearchText,
    select: `*,owner:owner_id (email)`,
  });

  const [throttledUpdateFiles] = useThrottle(filesQuery.refetch, 3000);
  // useRealtimeSingle({
  //   table: "files",
  //   id: filesQuery.data?.map((v) => v.id) ?? [],
  //   onUpdate: throttledUpdateFiles,
  // });
  const [throttledUpdateDocs] = useThrottle(docsQuery.refetch, 3000);
  const [throttledUpdateBookmarks] = useThrottle(bookmarksQuery.refetch, 3000);
  // useRealtimeSingle({
  //   table: "docs",
  //   id: docsQuery.data?.map((v) => v.id) ?? [],
  //   onUpdate: throttledUpdateDocs,
  // });
  const [throttledUpdateCollections] = useThrottle(
    collectionsQuery.refetch,
    3000
  );
  // useRealtimeSingle({
  //   table: "collections",
  //   id: collectionsQuery.data?.map((v) => v.id) ?? [],
  //   onUpdate: () => {
  //     console.log("refetch collections");
  //     throttledUpdateCollections();
  //   },
  // });

  const handleCreate = async (fileObj) => {
    // // const { data: sessionData } = await supabase.auth.getSession();
    // // const { session } = sessionData; // session.user.id
    // const { folder, name, path } = await uploadFile.mutateAsync(fileObj);
    // console.log("Uploaded file:", { folder, name, path, fileObj });
    // // get "permanent id" for file from: public.files where name=filePath
    // // - TODO: add bucket="private-files" as well
    // const { data, error } = await supabase.storage
    //   .from("private-files")
    //   .list(folder, {
    //     limit: 100,
    //   });
    // const { id: newFileId } = data[0];
    // refetchFiles();
    // navigate({
    //   search: (old) => ({
    //     ...old,
    //     type: "file",
    //     id: newFileId,
    //   }),
    // });
  };

  const categories = {
    Docs: {
      query: docsQuery,
      component: Docs2,
      refetchThrottleFn: throttledUpdateDocs,
    },
    Files: {
      query: filesQuery,
      component: Files2,
      refetchThrottleFn: throttledUpdateFiles,
    },
    Collections: {
      query: collectionsQuery,
      component: Collections2,
      refetchThrottleFn: throttledUpdateCollections,
    },
    Bookmarks: {
      query: bookmarksQuery,
      component: Bookmarks2,
      refetchThrottleFn: throttledUpdateBookmarks,
    },
  };
  // console.log("errrr:", docsQuery.error, docsQuery.error?.message);
  return (
    <>
      <div className="flex flex-nowrap space-x-1 p-1">
        <div className="flex-auto relative">
          <input
            placeholder="Search"
            className="px-2 py-1 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm border-0 outline-none ring-1 ring-gray-300 w-full"
            onChange={(e) => {
              setSearchText(e.target.value);
              navigate({
                search: (old) => ({
                  ...old,
                  search: e.target.value,
                }),
              });
            }}
            value={searchText}
            onKeyDown={(event) => {
              // on enter, save and exit editing
              if (event.key === "Enter") {
                filesQuery.refetch();
                docsQuery.refetch();
                collectionsQuery.refetch();
              }
            }}
          />
          {docsQuery.error?.message?.indexOf("tsquery") ? (
            <div className="absolute right-2 top-1">
              <a
                href="https://supabase.com/docs/guides/database/full-text-search#query-operators"
                className="group relative inline-block text-red-500 underline hover:text-red-500 duration-300"
                target="__blank"
              >
                <ExclamationIcon className="w-4 h-4" />
                <span className="absolute hidden z-10 group-hover:flex -top-2 -right-3 translate-x-full w-48 px-2 py-1 bg-gray-700 rounded-lg text-center text-white text-sm before:content-[''] before:absolute before:top-1/2  before:right-[100%] before:-translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-gray-700">
                  Invalid search phrase. <br />
                  Click for more.
                </span>
              </a>
            </div>
          ) : null}
        </div>
        <div>
          {/* <input
            style={{ display: "none" }}
            ref={fileUploadInputRef}
            type="file"
            onChange={handleFileChange}
          /> */}
          <NewThing
            user={user}
            filesQuery={filesQuery}
            docsQuery={docsQuery}
            collectionsQuery={collectionsQuery}
            bookmarksQuery={bookmarksQuery}
          />
        </div>
      </div>

      <Tab.Group>
        <div className="flex-initial">
          <Tab.List className="flex space-x-1">
            <Tab
              key={"sidebar"}
              className={({ selected }) =>
                classNames(
                  "w-full text-sm font-medium leading-4 py-1 focus:outline-0",
                  selected ? "underline font-bold bg-neutral-100" : ""
                )
              }
            >
              Sidebar
            </Tab>
            {Object.keys(categories).map((categoryName) => {
              const category = categories[categoryName];
              return (
                <Tab
                  key={categoryName}
                  className={({ selected }) =>
                    classNames(
                      "w-full text-sm font-medium leading-4 py-1 focus:outline-0",
                      selected ? "underline font-bold" : ""
                    )
                  }
                >
                  {category.query.data?.length ?? 0}
                  <br />
                  {categoryName}
                </Tab>
              );
            })}
          </Tab.List>
        </div>
        <div className="flex-auto overflow-y-auto">
          <Tab.Panels className="mt-2">
            <Tab.Panel key={0} className={classNames("", "")} unmount={false}>
              <Sidebar searchText={debouncedSearchText} />
            </Tab.Panel>
            {Object.values(categories).map((category, idx) => (
              <Tab.Panel
                key={idx + 1}
                className={classNames("", "")}
                // unmount={false}
              >
                <category.component
                  user={user}
                  query={category.query}
                  refetchThrottleFn={category.refetchThrottleFn}
                />
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </div>
      </Tab.Group>
    </>
  );
};

const Docs2 = ({ user, query, refetchThrottleFn }) => {
  const { data } = query;
  return (
    <div className="flex-auto overflow-y-auto">
      {data?.map((doc, i) => (
        <Doc
          key={`${i}-${doc.id}`}
          doc={doc}
          refetchThrottleFn={refetchThrottleFn}
        />
      ))}
    </div>
  );
};

const Files2 = ({ user, query, refetchThrottleFn }) => {
  const { data } = query;
  // console.log("files data:", data);
  return (
    <div className="flex-auto overflow-y-auto">
      {data?.map((file, i) => (
        <File
          key={`${i}-${file.id}`}
          file={file}
          refetchThrottleFn={refetchThrottleFn}
        />
      ))}
    </div>
  );
};

const Collections2 = ({ user, query, refetchThrottleFn }) => {
  const { data } = query;
  return (
    <div className="flex-auto overflow-y-auto">
      {data?.map((collection, i) => (
        <Collection
          key={`${i}-${collection.id}`}
          collection={collection}
          refetchThrottleFn={refetchThrottleFn}
        />
      ))}
    </div>
  );
};

const Bookmarks2 = ({ user, query, refetchThrottleFn }) => {
  const { data } = query;
  return (
    <div className="flex-auto overflow-y-auto">
      {data?.map((bookmark, i) => (
        <Bookmark
          key={`${i}-${bookmark.id}`}
          bookmark={bookmark}
          refetchThrottleFn={refetchThrottleFn}
        />
      ))}
    </div>
  );
};

const Doc = ({ doc: tmpDoc, refetchThrottleFn }) => {
  const search = useSearch();
  const { row: doc } = useRealtimeSingle({
    table: "docs",
    id: tmpDoc.id,
    initialRow: tmpDoc,
    onUpdate: (event) => {
      // setRealDoc(event.new);
      refetchThrottleFn();
    },
  });

  return (
    <div className={"p-1"}>
      <div className={"flex"}>
        {/* <div className={""}>
          <icon />
        </div> */}
        <div className={""}>
          <div className="">
            <Link
              search={{ type: "doc", id: doc.id }}
              className={`hover:underline ${
                search.id === doc.id ? "font-semibold" : ""
              }`}
            >
              {doc.title?.length ? doc.title : "--Untitled--"}
            </Link>
          </div>
          <div className="pl-0 text-gray-400 text-sm">
            <Owner id={doc.owner_id} owner={doc.owner} />
            <PermissionIcon obj={doc} />
            {/* {doc.is_public ? (
              <span className="ml-1 text-xs font-medium inline-block py-0.5 px-1 rounded-full text-purple-600 bg-purple-200 last:mr-0 ml-1 mr-1">
                <LockOpenIcon className="w-3 h-3 inline-block mr-0.5 -mt-1" />
                Public
              </span>
            ) : null} */}
            <CopySearchLink
              linkType="internal"
              type="doc"
              title={doc.title}
              id={doc.id}
            />
            <CopySearchLink
              linkType="md"
              type="doc"
              title={doc.title}
              id={doc.id}
            />
            {/* <span
              onClick={(e) => setCollection(collection.id)}
              className={"ml-1 cursor-pointer underline"}
            >
              link
            </span>
            <span
              onClick={(e) => setCollection(collection.id)}
              className={"ml-1 cursor-pointer underline"}
            >
              md link
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const Bookmark = ({ bookmark: tmpBookmark, refetchThrottleFn }) => {
  const search = useSearch();
  const { row: bookmark } = useRealtimeSingle({
    table: "bookmarks",
    id: tmpBookmark.id,
    initialRow: tmpBookmark,
    onUpdate: (event) => {
      // setRealDoc(event.new);
      refetchThrottleFn();
    },
  });

  return (
    <div className={"p-1"}>
      <div className={"flex"}>
        {/* <div className={""}>
          <icon />
        </div> */}
        <div className={""}>
          <div className="">
            <Link
              search={{ type: "bookmark", id: bookmark.id }}
              className={`hover:underline ${
                search.id === bookmark.id ? "font-semibold" : ""
              }`}
            >
              {bookmark.title?.length ? bookmark.title : "--Untitled--"}
            </Link>
          </div>
          <div className="pl-0 text-gray-400 text-sm">
            <Owner id={bookmark.owner_id} owner={bookmark.owner} />
            <PermissionIcon obj={bookmark} />
            {/* {bookmark.is_public ? (
              <span className="ml-1 text-xs font-medium inline-block py-0.5 px-1 rounded-full text-purple-600 bg-purple-200 last:mr-0 ml-1 mr-1">
                <LockOpenIcon className="w-3 h-3 inline-block mr-0.5 -mt-1" />
                Public
              </span>
            ) : null} */}
            <CopySearchLink
              linkType="internal"
              type="bookmark"
              title={bookmark.title}
              id={bookmark.id}
            />
            <CopySearchLink
              linkType="md"
              type="bookmark"
              title={bookmark.title}
              id={bookmark.id}
            />
            {/* <span
              onClick={(e) => setCollection(collection.id)}
              className={"ml-1 cursor-pointer underline"}
            >
              link
            </span>
            <span
              onClick={(e) => setCollection(collection.id)}
              className={"ml-1 cursor-pointer underline"}
            >
              md link
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const File = ({ file: tmpFile, refetchThrottleFn }) => {
  const search = useSearch();
  const { row: file } = useRealtimeSingle({
    table: "files",
    id: tmpFile.id,
    initialRow: tmpFile,
    onUpdate: (event) => {
      // setRealDoc(event.new);
      refetchThrottleFn();
    },
  });

  return (
    <div className={"p-1"}>
      <div className={"flex"}>
        {/* <div className={""}>
          <icon />
        </div> */}
        <div className={""}>
          <div className="">
            <Link
              search={{ type: "file", id: file.id }}
              className={`hover:underline ${
                search.id === file.id ? "font-semibold" : ""
              }`}
            >
              {file.title?.length ? file.title : "--Untitled--"}
            </Link>
          </div>
          <div className="pl-0 text-gray-400 text-sm">
            <Owner id={file.owner} owner={file.owned_by} />
            <PermissionIcon obj={file} />
            {/* {file.is_public ? (
              <span className="ml-1 text-xs font-medium inline-block py-0.5 px-1 rounded-full text-purple-600 bg-purple-200 last:mr-0 ml-1 mr-1">
                <LockOpenIcon className="w-3 h-3 inline-block mr-0.5 -mt-1" />
                Public
              </span>
            ) : null} */}
            <CopySearchLink
              linkType="internal"
              type="file"
              title={file.title}
              id={file.id}
            />
            <CopySearchLink
              linkType="md"
              type="file"
              title={file.title}
              id={file.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Collection = ({ collection: tmpCollection, refetchThrottleFn }) => {
  const search = useSearch();
  const { row: collection } = useRealtimeSingle({
    table: "collections",
    id: tmpCollection.id,
    initialRow: tmpCollection,
    onUpdate: (event) => {
      // setRealDoc(event.new);
      refetchThrottleFn();
    },
  });
  const setCollection = useAppStore((s) => s.setCollection);

  // console.log("row:", collection);

  return (
    <div className={"p-1"}>
      <div className={"flex"}>
        {/* <div className={""}>
          <icon />
        </div> */}
        <div className={""}>
          <div className="">
            <Link
              search={{ type: "collection", id: collection.id }}
              // search={{ collection: collection.id }}
              // onClick={(e) => setCollection(collection.id)}
              className={`hover:underline ${
                search.id === collection.id ? "font-semibold" : ""
              }`}
            >
              {collection.name?.length ? collection.name : "--Untitled--"}
            </Link>
          </div>
          <div className="pl-0 text-gray-400 text-sm">
            <Owner id={collection.owner_id} owner={collection.owner} />
            <PermissionIcon obj={collection} />
            {/* {collection.is_public ? (
              <span className="ml-1 text-xs font-medium inline-block py-0.5 px-1 rounded-full text-purple-600 bg-purple-200 last:mr-0 ml-1 mr-1">
                <LockOpenIcon className="w-3 h-3 inline-block mr-0.5 -mt-1" />
                Public
              </span>
            ) : null} */}
            <CopySearchLink
              linkType="internal"
              type="collection"
              title={collection.name}
              id={collection.id}
            />
            <CopySearchLink
              linkType="md"
              type="collection"
              title={collection.name}
              id={collection.id}
            />

            <span
              onClick={(e) => setCollection(collection.id)}
              className={"ml-1 cursor-pointer hover:underline"}
            >
              open in sidebar
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const NewThing = ({
  user,
  filesQuery,
  docsQuery,
  bookmarksQuery,
  collectionsQuery,
}) => {
  const createDoc = useCreateDoc();
  const createBookmark = useBookmarkCreate();
  const createFn = useCreate();
  const uploadFile = useUploadFile();
  const uploadFileProgress = useUploadFileProgress();
  const navigate = useNavigate();

  const handleCreateDoc = async () => {
    // const { data: sessionData } = await supabase.auth.getSession();
    // const { session } = sessionData; // session.user.id
    const doc = await createDoc.mutateAsync({
      title: "",
      text: "",
      owner_id: user.id,
    });
    // console.log("Created new doc:", doc);
    // refetchDocs();
    docsQuery.refetch();
    navigate({
      search: (old) => ({
        ...old,
        type: "doc",
        id: doc.id,
      }),
    });
  };

  const handleCreateBookmark = async () => {
    // const { data: sessionData } = await supabase.auth.getSession();
    // const { session } = sessionData; // session.user.id
    const doc = await createBookmark.mutateAsync({
      title: "",
      url: "",
      owner_id: user.id,
    });
    // console.log("Created new doc:", doc);
    // refetchBookmarks();
    bookmarksQuery.refetch();
    navigate({
      search: (old) => ({
        ...old,
        type: "bookmark",
        id: doc.id,
      }),
    });
  };

  const handleCreateCollection = async () => {
    // const { data: sessionData } = await supabase.auth.getSession();
    // const { session } = sessionData; // session.user.id
    const collection = await createFn.mutateAsync({
      table: "collections",
      row: {
        name: "",
        items: [],
        owner_id: user.id,
      },
    });
    // console.log("Created new doc:", doc);
    // refetchDocs();
    collectionsQuery.refetch();
    navigate({
      search: (old) => ({
        ...old,
        type: "collection",
        id: collection.id,
      }),
    });
  };

  const fileUploadInputRef = useRef(null);
  const openFileDialog = () => {
    fileUploadInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    event.target.value = null;

    // const uploadPromise = uploadFile.mutateAsync(fileObj);

    const toastId = toast.loading("Uploading...");

    const uploadPromise = uploadFileProgress.mutateAsync({
      file: fileObj,
      setProgress,
      onError,
      onUploadComplete,
    });

    function setProgress(e) {
      console.log("setProgress", e);
      const pct = Math.round((e.loaded / e.total) * 100);
      toast.loading(`Uploading ${pct}% (${formatBytes(e.loaded, 2)})`, {
        id: toastId,
      });
      // console.log(`Upload progress = ${e.loaded} / ${e.total} = ${pct}`);
    }
    function onUploadComplete(e) {
      // console.log("Search onUploadComplete");
      // toast.success("Uploaded", { id: toastId });
      // // toast.loading(`Uploading ${progress}%`);
      // // const pct = (e.loaded / e.total) * 100;
      // // console.log(`Upload progress = ${e.loaded} / ${e.total} = ${pct}`);
    }
    function onError(e) {
      // alert("Failed uploading");
      console.error("Failed uploading", e);
      toast.error("Failed Uploading", { id: toastId });
    }

    try {
      const result = await uploadPromise;
      console.log("Got result", result);
      const { folder, name, path } = result;
      console.log("Uploaded file:", { folder, name, path, fileObj });

      // get "permanent id" for file from: public.files where name=filePath
      // - TODO: add bucket="private-files" to table as well
      const { data, error } = await supabase.storage
        .from("private-files")
        .list(folder, {
          limit: 100,
        });

      const { id: newFileId } = data[0];

      toast.success("Uploaded", { id: toastId });

      filesQuery.refetch();
      navigate({
        search: (old) => ({
          ...old,
          type: "file",
          id: newFileId,
        }),
      });
    } catch (err) {
      console.error(err);
      toast.error("Failed Uploading", { id: toastId });
    }
  };

  // const handleFileChange = async (event) => {
  //   const fileObj = event.target.files && event.target.files[0];
  //   if (!fileObj) {
  //     return;
  //   }
  //   event.target.value = null;

  //   const uploadPromise = uploadFile.mutateAsync(fileObj);

  //   try {
  //     const result = await uploadPromise;
  //     console.log("Got result", result);
  //     const { folder, name, path } = result;
  //     console.log("Uploaded file:", { folder, name, path, fileObj });

  //     // get "permanent id" for file from: public.files where name=filePath
  //     // - TODO: add bucket="private-files" to table as well
  //     const { data, error } = await supabase.storage
  //       .from("private-files")
  //       .list(folder, {
  //         limit: 100,
  //       });

  //     const { id: newFileId } = data[0];

  //     filesQuery.refetch();
  //     navigate({
  //       search: (old) => ({
  //         ...old,
  //         type: "file",
  //         id: newFileId,
  //       }),
  //     });
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  return (
    <>
      <input
        style={{ display: "none" }}
        ref={fileUploadInputRef}
        type="file"
        onChange={handleFileChange}
      />
      <Menu as="div" className="relative">
        <div style={{}}>
          <Menu.Button className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus:ring-1">
            <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-50 right-0 mt-0 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleCreateDoc}
                    className={`${
                      active ? "bg-purple-500 text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    Doc
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleCreateBookmark}
                    className={`${
                      active ? "bg-purple-500 text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    Bookmark
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    // onClick={onNewFolder}
                    onClick={openFileDialog}
                    className={`${
                      active ? "bg-purple-500 text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    Upload File
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleCreateCollection}
                    className={`${
                      active ? "bg-purple-500 text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    Collection
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

const Owner = ({ id, owner }) => {
  const { data: user } = useUserQuery({ id: owner ? null : id });
  return owner?.email?.split("@")[0] ?? user?.email?.split("@")[0] ?? "";
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export default Search;
