import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../lib/api";

interface Bookmark {
  title: string;
  url: string;
  owner_id: string;
}

const createBookmark = async (bookmark: Bookmark) => {
  const { data, error } = await supabase
    .from("bookmarks")
    .insert(bookmark)
    .select()
    .maybeSingle();

  if (error) {
    throw new Error("Failed creating bookmark");
  }

  return data;
};

export default function useCreateBookmark() {
  return useMutation((bookmark: Bookmark) => createBookmark(bookmark), {});
}
