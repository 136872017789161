import { useQuery } from "react-query";
import { supabase } from "../lib/api";

const getFiles = async ({ text }) => {
  let { data, error } = await supabase
    .from("files")
    .select(
      `
            *,
            owned_by:owner (
                email,
                first_name,
                last_name
            )`
    )
    .ilike("title", `%${text}%`)
    .limit(50)
    .order("created_at", { ascending: false });

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error("File not found");
  }

  return data;
};

export default function useFilesQuery({ text }, params = {}) {
  return useQuery(["files", { text }], () => getFiles({ text }), {
    keepPreviousData: true,
    ...params,
  });
}
