// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import { supabase } from "../lib/api";
import {
  FolderIcon,
  FolderOpenIcon,
  FolderRemoveIcon,
  QuestionMarkCircleIcon,
  LockClosedIcon,
  LockOpenIcon,
  RefreshIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";
import { cx, css } from "@emotion/css";

import { useSearch, useNavigate } from "@tanstack/react-location";

import {
  useThrottle,
  useDebouncedValue,
  useCountdown,
  useMapState,
} from "rooks";

import CopyLink from "../lib/CopyLink";

import useAppStore from "../store/app";

// @ts-ignore
import KeyboardEventHandler from "react-keyboard-event-handler";

import useUserQuery from "../hooks/useUserQuery";
import useSingleQuery from "../hooks/useSingleQuery";
import useExistsQuery from "../hooks/useExistsQuery";
import useCollectionUpdate from "../hooks/useCollectionUpdate";
import useRealtime from "../hooks/useRealtime";
import PermissionsHeader from "./PermissionsHeader";

import { CollectionEditor } from "./Sidebar";

const ViewCollection = ({ user }) => {
  const [status, setStatus] = useState("view"); // view / edit
  const [viewStatus, setViewStatus] = useState("view"); // view / markdown
  const [editStatus, setEditStatus] = useState("edit"); // edit / preview

  const [name, setName] = useState("");
  const [autosaveNum, setAutosaveNum] = useState(0);
  const [debouncedValue, immediatelyUpdateDebouncedValue] = useDebouncedValue(
    autosaveNum,
    1500
  );
  // const realSaveNum = useThrottle(autosaveNum, 3000); // should use debounce instead?
  const waitingToSave = autosaveNum !== debouncedValue ? true : false; // autosaveNum !== realSaveNum ? true : false;

  // autosave
  useEffect(() => {
    if (status === "edit" && debouncedValue) {
      // console.log("SAVE UPDATED CONTENT", autosaveNum);
      // console.log("autosave", autosaveNum);
      handleSave();
    }
  }, [status, debouncedValue]);
  // console.log("num", autosaveNum, debouncedValue);

  const setCurrentlyViewing = useAppStore((s) => s.setCurrentlyViewing);
  const search = useSearch();
  const navigate = useNavigate();

  const {
    data: collection,
    isLoading: isLoadingCollection,
    isError: isErrorCollection,
    refetch: refetchCollection,
  } = useSingleQuery({
    table: "collections",
    id: search.id,
    select: `
      *,
      owned_by:owner_id (
          email
      )`,
  });

  const {
    data: existsObj,
    isLoading: isLoadingExistsObj,
    isError: isErrorExistsObj,
    refetch: refetchExistsObj,
  } = useExistsQuery({
    table: "collections",
    pk: search.id,
  });

  const onUpdateCollection = React.useCallback((newCollection) => {
    // setCollection(newCollection)
    refetchCollection();
  }, []);

  const { presenceState, channel, setPresence } = useRealtime({
    room: search.id,
    user,
    // id: search.id,
    // table: "collections",
    // onUpdate: onUpdateCollection,
  });
  const presenceIsCurrentlyEditing = isEditing(presenceState);

  // console.log("presenceState:", presenceState);
  // console.log("presenceIsCurrentlyEditing:", presenceIsCurrentlyEditing);

  useEffect(() => {
    // console.log("collection.name updated:", collection?.name);
    if (collection) {
      setName(collection.name);
      setCurrentlyViewing({
        title: collection.name,
        type: "collection",
        id: collection.id,
      });
      // document.title = collection.name;
    }
    return () => {
      setCurrentlyViewing(null);
    };
  }, [collection?.name]);

  const updateCollection = useCollectionUpdate();

  const handleTogglePublic = async () => {
    await updateCollection.mutateAsync({
      id: collection.id,
      is_public: !collection.is_public,
    });
    refetchCollection();
  };
  const handleSave = async () => {
    if (status !== "edit") {
      // console.log("status not edit");
      return;
    }
    // console.log("do save");

    // TODO: have a "last saved" value, dont save if we don't need to (ie, if I deleted all the stuff I just typed)

    await updateCollection.mutateAsync({
      id: collection.id,
      name: name,
    });
    refetchCollection();
  };

  const leaveEdit = () => {
    setPresence({ editing: false });
    setStatus("view");
  };
  useEffect(() => {
    if (
      status === "edit" &&
      presenceIsCurrentlyEditing?.length &&
      presenceIsCurrentlyEditing?.[0]?.user !== user.id
    ) {
      alert("Sorry, being kicked because you are no longer the editor");
      leaveEdit();
    }
  }, [status, presenceIsCurrentlyEditing, user]);

  const handleStartEdit = () => {
    // console.log("presenceIsCurrentlyEditing", presenceIsCurrentlyEditing);
    if (presenceIsCurrentlyEditing?.length) {
      alert("Unable to start editing while somebody else already is");
      return;
    }
    setStatus("edit");
    setEditStatus("edit");
  };
  useEffect(() => {
    setPresence({ editing: status === "edit" ? true : false });
  }, [status]);

  useEffect(() => {
    if (
      status !== "edit" && // editing
      collection && // collection exists
      !collection.name?.length &&
      collection.owner_id === user.id
    ) {
      // // no title or text
      // handleStartEdit();
      // navigate({
      //   search: (old) => ({
      //     ...old,
      //     edit: undefined,
      //   }),
      //   replace: true,
      // });
    }
  }, [status, collection]);

  const handleKeyEvent = (key: any, e: any) => {
    //   if (!canEdit) return;

    switch (key) {
      case "ctrl+s":
      case "meta+s":
      case "cmd+s":
        handleSave();
        e.preventDefault();
        break;
      default:
        break;
    }
  };

  // console.log("status:", status);
  // console.log("viewStatus:", viewStatus);
  // console.log("editStatus:", editStatus);

  if (!collection && isLoadingCollection) {
    return (
      <div className="h-full flex items-center justify-center text-center">
        <div>
          <LoadingIcon />
          <div className="mt-2 text-sm text-gray-400">Loading Collection</div>
        </div>
      </div>
    );
  }

  if (!collection && isErrorCollection) {
    if (existsObj?.data) {
      return (
        <div className="h-full flex items-center justify-center text-center">
          <div>
            <ExclamationIcon className="h-5 w-5 inline-block text-orange-500" />
            <div className="mt-2 text-sm text-orange-500">
              You do not have the necessary permissions to view this collection.
              <br />
              Contact the collection owner for access:{" "}
              <span className="font-bold">
                <Owner
                  id={existsObj.data.owner_id}
                  owner={existsObj.data.owner}
                />
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="h-full flex items-center justify-center text-center">
          <div>
            <ExclamationIcon className="h-5 w-5 inline-block text-red-400" />
            <div className="mt-2 text-sm text-red-400">
              Error loading collection
            </div>
          </div>
        </div>
      );
    }
  }

  if (!collection) {
    console.error(
      "Oops, should not be rendering here unless there was a bigger problem?"
    );
    return null;
  }

  let collectionname = collection?.name?.split("/") ?? [];
  collectionname = collectionname.length
    ? collectionname[collectionname.length - 1]
    : "";
  // console.log("collectionname:", collectionname);

  return (
    <>
      <KeyboardEventHandler
        handleKeys={["meta+s", "ctrl+s", "meta"]}
        onKeyEvent={handleKeyEvent}
        handleFocusableElements
      />
      <div className="border-b bg-white">
        <div className="flex items-center space-x-1 px-1 py-1">
          <div className="">
            <PermissionsHeader
              obj={collection}
              user={user}
              updateObj={updateCollection}
              refetch={refetchCollection}
            />
          </div>
          <div className="text-sm">
            <Owner id={collection.owner_id} owner={collection.owner} />
          </div>
          <div className="pl-2">
            <CopyLink link={`/?type=collection&id=${collection.id}`} />
          </div>
          <div className="flex-auto"></div>
          {presenceIsCurrentlyEditing?.length ? (
            <div className="text-green-500 text-sm">
              Editing:{" "}
              <CurrentEditor user_id={presenceIsCurrentlyEditing[0].user} />
            </div>
          ) : null}
          {status === "edit" ? (
            <div className="">
              <span
                type="span"
                // className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-sm  "
                className="inline-flex justify-center rounded-md border border-transparent px-2 py-1 text-sm  text-gray-400 "
              >
                {updateCollection.isLoading || waitingToSave
                  ? "Saving..."
                  : "Saved"}
              </span>
            </div>
          ) : null}
          {/* {status === "view" ? (
            <>
              <div className="">
                <button
                  onClick={handleStartEdit}
                  className={`inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2`}
                >
                  Edit
                </button>
              </div>
            </>
          ) : null} */}
          {status === "edit" ? (
            <>
              <div className="">
                <button
                  onClick={leaveEdit}
                  className={`inline-flex justify-center rounded-md border border-transparent bg-green-100 px-2 py-1 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2`}
                >
                  Done Editing
                </button>
              </div>
            </>
          ) : null}
        </div>
      </div>
      {/* <div
        className="border-b"
        onDoubleClick={(event) => {
          if (status !== "edit") {
            handleStartEdit();
            setTimeout(() => {
              event.target.focus();
            }, 100);
          }
        }}
      >
        <input
          disabled={status === "view" ? true : false}
          className="w-full text-xl px-2 py-1 outline-none bg-white"
          onChange={(e) => {
            setName(e.target.value);
            // throttledFunction(realSaveNum + 1);
            setAutosaveNum((v) => v + 1);
          }}
          onKeyDown={(event) => {
            // on enter, save and exit editing
          }}
          value={name}
          placeholder="Unnamed"
        />
      </div> */}
      <div className="h-full overflow-y-auto px-2 py-1 bg-white">
        <div className={``}>
          {/* View */}
          {/* <div className="mt-2">
            Download as:{" "}
            <a
              href="#"
              className="text-blue-600 hover:text-blue-500 underline hover:underline"
            >
              {collectionname}
            </a>
          </div>
          <div className="mt-2">
            <a
              href="#"
              className="text-blue-600 hover:text-blue-500 underline hover:underline"
            >
              Get Signed URL (with expiration)
            </a>
          </div> */}

          <div className="mt-2">
            <CollectionEditor id={collection.id} defaultExpandAll />
          </div>
        </div>
      </div>
    </>
  );
};

const DownloadLink = ({ collection }) => {
  const [
    map,
    { set, setMultiple, has, remove, removeMultiple, removeAll },
  ] = useMapState({
    status: "none",
    endTime: new Date(),
    timeout: 60,
    link: "",
  });

  const onEnd = () => {
    if (map.status === "countdown") {
      set("status", "done");
    }
  };

  const count = useCountdown(map.endTime, {
    interval: 1000,
    // onDown: (time) => console.log("onDown", time),
    onEnd,
  });

  const collectionLinkMutatation = useCollectionLinkMutation();

  const handleCreateLink = async () => {
    set("status", "creating");
    const { signedUrl, expires } = await collectionLinkMutatation.mutateAsync({
      path: collection.name,
      timeout: 60,
    });
    console.log("expires:", expires);
    setMultiple({
      status: "countdown",
      endTime: new Date(expires),
      link: signedUrl,
    });
  };
  const handleCreateLinkTimeout = async () => {
    let newTime = window.prompt("Expiration in seconds:", map.timeout);
    if (!newTime) {
      return false;
    }

    newTime = parseInt(newTime, 10);
    set("timeout", newTime);

    set("status", "creating");

    const { signedUrl, expires } = await collectionLinkMutatation.mutateAsync({
      path: collection.name,
      timeout: newTime,
    });

    setMultiple({
      status: "countdown",
      endTime: new Date(expires),
      link: signedUrl,
    });
  };

  switch (map.status) {
    case "none":
      return (
        <div className="">
          <a
            className="underline text-blue-500 hover:text-blue-400 cursor-pointer"
            onClick={handleCreateLink}
          >
            Create a link
          </a>{" "}
          (
          <a
            className="underline text-blue-500 hover:text-blue-400 cursor-pointer"
            onClick={handleCreateLinkTimeout}
          >
            {map.timeout}s
          </a>
          )
        </div>
      );
    case "creating":
      return <div className="">Creating link...</div>;
    case "countdown":
      return (
        <div className="">
          <div className="mb-1">
            <a
              href={map.link}
              className="underline text-blue-500 hover:text-blue-400"
              target="_blank"
            >
              Link (opens in new window)
            </a>
          </div>
          <span className="text-xs font-medium inline-block py-0.5 px-1 rounded-full text-cyan-600 bg-cyan-200 last:mr-0 ml-1 mr-1">
            Expires: {count}s
          </span>
        </div>
      );
    case "done":
    default:
      return (
        <div className="">
          <div className="mb-1">
            <a
              className="underline text-blue-500 hover:text-blue-400 cursor-pointer"
              onClick={handleCreateLink}
            >
              Create a link
            </a>{" "}
            (
            <a
              className="underline text-blue-500 hover:text-blue-400 cursor-pointer"
              onClick={handleCreateLinkTimeout}
            >
              {map.timeout}s
            </a>
            )
          </div>
          <span className="text-xs font-medium inline-block py-0.5 px-1 rounded-full text-orange-600 bg-orange-200 last:mr-0 ml-1 mr-1">
            Link Expired
          </span>
        </div>
      );
  }
  return count;

  return null;
};

const RenderCollectionPreview = ({ collection }) => {
  // render collection preview depending on type of collection
  let PreviewComponent = () => null;

  const { data: collectionLinkInfo } = useCollectionLinkQuery({
    path: collection.name,
  });
  // console.log("collectionLinkInfo:", collectionLinkInfo);
  // useEffect(() => {
  //   if (collection) {
  //     collectionLinkMutatation.mutateAsync({ path: collection.name });
  //   }
  // }, [collection?.name]);
  // useEffect(() => {

  // }, [collectionLinkInfo]);

  switch (collection.metadata.mimetype) {
    case "image/png":
      PreviewComponent = PreviewImage;
      break;
    default:
      break;
  }

  const link = collectionLinkInfo?.signedUrl;

  if (!link) {
    return null;
  }

  return <PreviewComponent collection={collection} link={link} />;
};

const PreviewImage = ({ collection, link }) => {
  return <img className="max-w-full" src={link} />;
};

const LoadingIcon = () => {
  return (
    <svg
      className="animate-spin h-5 w-5 inline-block text-gray-400"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

const Owner = ({ id, owner }) => {
  const { data: user } = useUserQuery({ id: owner ? null : id });
  return owner?.email?.split("@")[0] ?? user?.email?.split("@")[0] ?? "";
};

const CurrentEditor = ({ user_id }) => {
  const { data: user } = useUserQuery({ id: user_id });
  // console.log("info", { user, user_id });
  return `${user?.first_name} ${user?.last_name}`;
};

const isEditing = (presenceState) => {
  return Object.keys(presenceState)
    .map((k) => presenceState[k][0])
    .filter((v) => v.editing);
};

export default ViewCollection;
