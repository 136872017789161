// @ts-nocheck
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import useSingleQuery from "../hooks/useSingleQuery";
import { useList } from "react-use";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useKeyValueUpdate from "../hooks/useKeyValueUpdate";
import { supabase } from "../lib/api";

const SidebarEditorDialog = ({ onComplete }) => {
  const isOpen = true;

  const {
    data: kvSidebar,
    isLoading: kvSidebarIsLoading,
    isError: kvSidebarIsError,
    refetch: kvSidebarRefetch,
  } = useSingleQuery({
    table: "keyvalues",
    pkField: "key",
    id: "sidebar",
    ownerIdField: "user_id",
    select: `*`,
    updateOnRealtime: false,
  });

  const updateKeyValue = useKeyValueUpdate();

  const [
    items,
    arr,
    // {
    //   set,
    //   push,
    //   updateAt,
    //   insertAt,
    //   update,
    //   updateFirst,
    //   upsert,
    //   sort,
    //   filter,
    //   removeAt,
    //   clear,
    //   reset,
    // },
  ] = useList([]);

  useEffect(() => {
    // console.log("kvsidebar:", kvSidebar);
    if (kvSidebar) {
      arr.set(
        kvSidebar?.value?.collections?.map((c, i) => ({
          id: `id-${i}`,
          collectionId: c,
        })) ?? []
      );
    }
  }, kvSidebar);

  function closeModal() {
    onComplete(null);
  }

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = useCallback(
    (result) => {
      // alert(1);
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      const newItems = reorder(
        items,
        result.source.index,
        result.destination.index
      );

      arr.set(newItems);
    },
    [items]
  );

  const handleSave = async () => {
    if (!kvSidebar) {
      return false;
    }
    await updateKeyValue.mutateAsync({
      id: kvSidebar.id,
      value: { collections: items.map((i) => i.collectionId) },
    });
    onComplete();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Re-order Sidebar Collections
                </Dialog.Title>
                <div className="mt-2 relative">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className={classNames(
                            provided.droppableProps.className,
                            "select-none p-2",
                            snapshot.isDraggingOver ? "" : ""
                          )}
                        >
                          {items.map((item, index) => (
                            <Collection
                              key={item.id}
                              item={item}
                              index={index}
                            />
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
                <hr />
                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-blue-900 hover:bg-red-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 mr-4"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={handleSave}
                  >
                    Save Changes
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const Collection = ({ item, index }) => {
  const {
    data: collection,
    isLoading: collectionIsLoading,
    isError: collectionIsError,
    refetch: collectionRefetch,
  } = useSingleQuery({
    table: "collections",
    id: item.collectionId,
    select: `id, name`,
    updateOnRealtime: false,
  });

  setTimeout(() => {
    // debugger;
  }, 2000);

  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <DraggablePortalHandler snapshot={snapshot}>
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={classNames(
              provided.draggableProps.className,
              "rounded p-2 hover:bg-blue-500/20 z-100",
              `${snapshot.isDragging ? "bg-gray-500/20" : ""}`
            )}
            // style={{ ...provided.draggableProps.style, left: 0, top: 0 }}
          >
            {collection?.name ?? "Loading..."}
          </div>
        </DraggablePortalHandler>
      )}
    </Draggable>
  );
};

function DraggablePortalHandler({ children, snapshot }) {
  if (snapshot.isDragging)
    return ReactDOM.createPortal(children, document.body);
  return children;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default SidebarEditorDialog;
