import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../lib/api";

const collectionUpdate = async (collection) => {
  const { data, error } = await supabase
    .from("collections")
    .update(collection)
    .eq("id", collection.id);

  if (error) {
    throw new Error("Failed updating collection");
  }

  return data;
};

export default function useCollectionUpdate() {
  return useMutation((collection) => collectionUpdate(collection), {});
}
