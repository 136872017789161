// @ts-nocheck
import React, { useEffect, useState, useRef, Fragment } from "react";
import { supabase } from "../lib/api";
import Tree, { TreeNode } from "rc-tree";
import {
  FolderIcon,
  FolderOpenIcon,
  FolderRemoveIcon,
  CollectionIcon,
  DocumentTextIcon,
  LinkIcon,
  QuestionMarkCircleIcon,
  ExternalLinkIcon,
  PlusSmIcon,
  LockOpenIcon,
  DownloadIcon,
  CogIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { cx, css } from "@emotion/css";

import { v4 as uuidv4 } from "uuid";

import useSingleQuery from "../hooks/useSingleQuery";
import useKeyValueUpdate from "../hooks/useKeyValueUpdate";
import useCollectionUpdate from "../hooks/useCollectionUpdate";
import SidebarEditorDialog from "./SidebarEditorDialog";
import CollectionFolderDialog from "./CollectionFolderDialog";
import CollectionItemDialog from "./CollectionItemDialog";
import PermissionIcon from "./PermissionIcon";
import { Menu, Transition } from "@headlessui/react";

import { Link, useSearch, useNavigate } from "@tanstack/react-location";
import ClickAwayListener from "react-click-away-listener";

import { useLocalstorageState } from "rooks";

import Highlighter from "react-highlight-words";
import useRealtimeSingle from "../hooks/useRealtimeSingle";
import Search from "./Search";

import useAppStore from "../store/app";

const Sidebar = ({ user, searchText = "" }) => {
  // const

  const search = useSearch();
  const [editSidebar, setEditSidebar] = useState(null);
  // const [kvSidebar, setKvSidebar] = useState(null);
  const [todos, setTodos] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const newTaskTextRef = useRef();
  const [errorText, setError] = useState("");

  const updateKeyValue = useKeyValueUpdate();

  const tmpCollectionId = useAppStore((s) => s.collection);
  const setCollection = useAppStore((s) => s.setCollection);

  const {
    data: kvSidebar,
    isLoading: kvSidebarIsLoading,
    isError: kvSidebarIsError,
    refetch: kvSidebarRefetch,
  } = useSingleQuery({
    table: "keyvalues",
    pkField: "key",
    id: "sidebar",
    ownerIdField: "user_id",
    select: `*`,
  });

  // console.log("kvSidebar", kvSidebar?.value);
  // const collectionIds = kvSidebar?.value?.collections ?? []
  const [collectionIds, setCollectionIds] = useState([]);
  useEffect(() => {
    setCollectionIds(kvSidebar?.value?.collections ?? []);
  }, [kvSidebar]);

  const handleRemoveCollectionFromSidebar = async (index) => {
    let newCollectionIds = [...collectionIds];
    newCollectionIds.splice(index, 1);
    await updateKeyValue.mutateAsync({
      id: kvSidebar.id,
      value: { collections: newCollectionIds },
    });
    kvSidebarRefetch();
  };

  const handleAddCollectionToSidebar = async (collectionId) => {
    await updateKeyValue.mutateAsync({
      id: kvSidebar.id,
      value: { collections: [...collectionIds, collectionId] },
    });
    kvSidebarRefetch();
  };

  // const { presenceState, channel, setPresence } = useRealtime({
  //   // room: search.id,
  //   // user,
  //   id: id,
  //   table: "collections",
  //   onUpdate: (newCollection) => {
  //     // console.log("collection updated remotely");
  //     setCollection(newCollection);
  //     setGData(newCollection.items);
  //   },
  // });

  // useEffect(() => {
  //   fetchSidebar().catch(console.error);
  // }, []);

  // const fetchSidebar = async () => {
  //   const { data: sessionData } = await supabase.auth.getSession();
  //   const { session } = sessionData;
  //   let { data: keyvalue, error } = await supabase
  //     .from("keyvalues")
  //     .select("*")
  //     .eq("key", "sidebar")
  //     .eq("user_id", session.user.id)
  //     .single();
  //   if (error) {
  //     console.log("error (no sidebar keyvalue)", error);
  //     // else setTodos(todos);
  //   } else {
  //     // console.log("keyvalue", keyvalue);
  //     setKvSidebar(keyvalue);
  //   }
  // };

  return (
    <>
      {/* <div className="border-b">
                <div className="relative w-full">
                    <input
                        placeholder="Filter"
                        className="border-gray-300 px-4 outline-none w-full"
                    />
                    {showSearch ? (
                        <ul className="bg-white border-[1px] rounded-md shadow-md p-4 absolute max-h-[200px] overflow-y-auto">
                            {searchResults.map}
                        </ul>
                    ) : null}
                </div>
            </div> */}
      <div className="relative flex-grow ">
        {editSidebar ? (
          <SidebarEditorDialog
            onComplete={() => {
              kvSidebarRefetch();
              setEditSidebar(null);
            }}
          />
        ) : null}
        {collectionIds.map((cid, i) => (
          <CollectionEditor
            key={`${cid}-${i}`}
            id={cid}
            onRemove={(e) => handleRemoveCollectionFromSidebar(i)}
            storagePrefix={"sidebar-collection"}
            searchText={searchText}
          />
        ))}
        <div>
          <span
            className="absolute top-1 right-16 cursor-pointer"
            onClick={() => setEditSidebar(true)}
            title="Re-order sidebar collections"
          >
            <CogIcon className="w-5 h-5" />
          </span>
        </div>
        {tmpCollectionId ? (
          <>
            <div className="border-t-8 border-b text-center">
              Viewing Collection:{" "}
              <span
                className="cursor-pointer underline"
                onClick={() => {
                  handleAddCollectionToSidebar(tmpCollectionId);
                  setCollection(null);
                }}
              >
                add above
              </span>
            </div>
            <CollectionEditor key={tmpCollectionId} id={tmpCollectionId} />
          </>
        ) : null}
      </div>
    </>
  );
};

export const CollectionEditor = ({
  id,
  onRemove,
  defaultExpandAll = false,
  storagePrefix = "collection",
  searchText = "",
}) => {
  const [editing, setEditing] = useState(null);
  const [folderInfo, setFolderInfo] = useState(null);
  const [collection, setCollection] = useState(null);
  const [todos, setTodos] = useState([]);
  const newTaskTextRef = useRef();
  const [errorText, setError] = useState("");

  const [showCollection, setShowCollection] = useLocalstorageState(
    [storagePrefix, id, "show"].join("-"),
    true
  );

  const [
    defaultKeysOpenFromLocalStorage,
    setDefaultKeysOpenFromLocalStorage,
  ] = useLocalstorageState([storagePrefix, id, "keys"].join("-"), null);

  const [showContextMenu, setShowContextMenu] = useState(null);

  const search = useSearch();
  const navigate = useNavigate();

  const updateCollection = useCollectionUpdate();

  useRealtimeSingle({
    table: "collections",
    id: id,
    onUpdate: (event) => {
      // console.log("collection update (sidebar)");
      // console.log("sidebar collection changed");
      // throttledUpdateCollections();
      setCollection(event.new);
      setGData(event.new.items);
    },
  });

  // useRealtimeSingle({
  //   // room: search.id,
  //   // user,
  //   id: id,
  //   table: "collections",
  //   onUpdate: (newCollection) => {
  //     // console.log("collection updated remotely");
  //     setCollection(newCollection);
  //     setGData(newCollection.items);
  //   },
  // });

  const [gData, setGData] = useState([
    // {
    //     key: "0",
    //     type: "folder",
    //     title: "folder 1",
    //     children: [
    //         { key: "0-0", type: "doc", title: "test00" },
    //     ],
    // },
  ]);

  useEffect(() => {
    fetchCollection().catch(console.error);
  }, []);

  // Get "ViewingKeys" => keys that we highlight because the Doc/etc is currently being viewed
  // TODO: shouldn't be calculating this every time?
  // TODO: re-use functionality for "filtering" items in a collection?
  let viewingKeys = [];
  let searchFoundKeys = [];
  const loopForKeys = (data) => {
    let didFind = {
      viewing: false,
      search: false,
    };
    data.map((item) => {
      if (
        (search.id && search.id === item.id) ||
        (search.url && search.url === item.url)
      ) {
        viewingKeys.push(item.key);
        didFind.viewing = true;
      }
      if (
        searchText?.length &&
        item.title?.toLowerCase().includes(searchText?.toLowerCase()) //||
        // item.url?.toLowerCase().includes(searchText?.toLowerCase())
      ) {
        searchFoundKeys.push(item.key);
        didFind.search = true;
      }
      if (item.children) {
        let found = loopForKeys(item.children);
        if (found.viewing) {
          viewingKeys.push(item.key);
        }
        if (found.search) {
          searchFoundKeys.push(item.key);
        }
      }
    });
    return didFind;
  };
  loopForKeys(gData);

  const [expandedKeys, setExpandedKeys] = useState([]);
  useEffect(() => {
    // we cannot set it in the useState above, because it breaks rc-tree somehow (sometimes...some of the folders are not collapseable)
    setTimeout(() => {
      setExpandedKeys([...(defaultKeysOpenFromLocalStorage ?? [])]);
    }, 1);
  }, []);
  const [selectedKeys, setSelectedKeys] = useState([]);
  useEffect(() => {
    if (expandedKeys === null && collection) {
      // only set on first load
      // console.log("set default expanded keys");
      if (defaultExpandAll) {
        // console.log("setting to defaults");
        setExpandedKeys(getAllKeys(collection.items));
      }
    }
  }, [collection?.items]);

  const fetchCollection = async () => {
    let { data: collection, error } = await supabase
      .from("collections")
      .select("*")
      .eq("id", id)
      .maybeSingle();
    if (error) {
      console.log("error (failed loading collection)", error);
      // else setTodos(todos);
    } else {
      // setGData(collection.)
      // console.log("collection:", collections);
      // setCollection(collections[0])
      if (collection) {
        setCollection(collection);
        setGData(collection.items);
      } else {
        setGData([{ type: "invalid", title: "Invalid Collection" }]);
      }
    }
  };

  const handleDeleteItem = async (deleteItem) => {
    const loop = (data, key, callback) => {
      data.forEach((item, index, arr) => {
        if (item.key === key) {
          callback(item, index, arr);
          return;
        }
        if (item.children) {
          loop(item.children, key, callback);
        }
      });
    };
    const data = [...gData];

    loop(data, deleteItem.key, (item, index, arr) => {
      arr.splice(index, 1);
    });

    updateCollection.mutateAsync({ id, items: data });
    setGData(data);
  };

  const handleAddItem = async (parentKey, newItem, andEdit) => {
    // console.log({ parentKey, newItem, andEdit });
    const loop = (data, key, callback) => {
      data.forEach((item, index, arr) => {
        if (item.key === key) {
          callback(item, index, arr);
          return;
        }
        if (item.children) {
          loop(item.children, key, callback);
        }
      });
    };
    const data = [...gData];
    const newItemWithKey = {
      key: uuidv4(),
      ...newItem,
    };
    // console.log("parentKey:", parentKey);
    if (parentKey === true) {
      // adding to collection!
      // console.log("adding to collection");
      data.unshift(newItemWithKey);
    } else {
      // adding to folder
      loop(data, parentKey, (item, index, arr) => {
        item.children.unshift(newItemWithKey);
      });
    }

    updateCollection.mutateAsync({ id, items: data });
    setGData(data);
    if (andEdit) {
      setEditing(newItemWithKey);
    }
  };

  const handleEditCollection = async (collection) => {
    const name = window.prompt("Name:", collection.name);
    if (name === null) {
      return false;
    }
    await updateCollection.mutateAsync({ id, name });
    fetchCollection();
  };

  const deleteTodo = async (id) => {
    // try {
    //     await supabase.from("todos").delete().eq("id", id);
    //     setTodos(todos.filter((x) => x.id !== id));
    // } catch (error) {
    //     console.log("error", error);
    // }
  };

  const addTodo = async () => {
    // let taskText = newTaskTextRef.current.value;
    // let task = taskText.trim();
    // if (task.length <= 3) {
    //     setError("Task length should be more than 3!");
    // } else {
    //     let { data: todo, error } = await supabase
    //         .from("todos")
    //         .insert({ task, user_id: user.id })
    //         .single();
    //     if (error) setError(error.message);
    //     else {
    //         setTodos([todo, ...todos]);
    //         setError(null);
    //         newTaskTextRef.current.value = "";
    //     }
    // }
  };

  const onDragStart = (info) => {
    // console.log("start", info);
  };

  const onDragEnter = () => {
    // console.log("drag enter");
  };

  const onDrop = (info) => {
    // console.log("drop", info);
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dragItem = info.dragNode.item;
    const dropItem = info.node.item;
    const dropPos = info.node.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      data.forEach((item, index, arr) => {
        if (item.key === key) {
          callback(item, index, arr);
          return;
        }
        if (item.children) {
          loop(item.children, key, callback);
        }
      });
    };
    const data = [...gData];

    // Find dragObject, dropObject
    let dragObj, dropObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (dropPosition === 0) {
      // Drop on the content
      // console.log("dropping on content!", dropKey);

      switch (dropItem.type) {
        case "folder":
          loop(data, dropKey, (item) => {
            // eslint-disable-next-line no-param-reassign
            item.children = item.children || [];
            // where to insert
            item.children.unshift(dragObj);
          });
          break;
        default:
          // Drop on the gap (insert before or insert after)
          let ar;
          let i;
          loop(data, dropKey, (item, index, arr) => {
            ar = arr;
            i = index;
          });
          if (dropPosition === -1) {
            ar.splice(i, 0, dragObj);
          } else {
            ar.splice(i + 1, 0, dragObj);
          }
          break;
      }
    } else {
      // Drop on the gap (insert before or insert after)
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    // console.log("Updated:", data);
    updateCollection.mutateAsync({ id, items: data });
    setGData(data);
  };

  const allowDrop = ({ dropNode, dragNode, dropPosition }) => {
    // console.log("dropNode", dropPosition);
    switch (dropNode.item.type) {
      case "collection":
      case "folder":
      default:
        // console.log("allow drop");
        return true;
      // default:
      //     console.log("deny drop", dropNode);
      //     return false;
    }
    // if (!dropNode.children) {
    //     // if (dropPosition === 0) return false;
    //     // console.log("no drop allowed");
    //     // return false;
    // }
    // return true;
  };

  const handleContextMenu = ({ node, event }) => {
    setShowContextMenu({ node, pageX: event.pageX, pageY: event.pageY });
    // Object.assign(this.cmContainer.style, {
    //     position: "absolute",
    //     left: `${info.event.pageX}px`,
    //     top: `${info.event.pageY}px`,
    // });
  };

  const loop = (data) =>
    data.map((item) => {
      let icon;
      switch (item.type) {
        case "folder":
          // icon = <></>;
          icon = <FolderIcon />;
          // item.children.length ? (
          //     <FolderIcon />
          // ) : (
          //     <FolderRemoveIcon />
          // );
          break;
        case "doc":
          icon = <DocumentTextIcon />;
          break;
        case "url":
        case "bookmark":
          icon = <LinkIcon />;
          break;
        case "collection":
          icon = <CollectionIcon />;
          break;
        case "file":
          icon = <DownloadIcon />;
          break;
        default:
          break;
      }
      const onClick = (e) => {
        // do not block here! instead, should be checking/blocking in the ItemDropdown (on the menu button, intercept that?)
        // console.log("e", e);
        // ignore clicks in the Menu Button
        if (
          isDescendant(document.getElementsByClassName("is-menu"), e.target)
        ) {
          //
          // console.log("Menu was pressed");
          e.stopPropagation();
          e.preventDefault();
          return false;
        }
        // console.log("not descendent");
        // return false;

        // switch (item.type) {
        //     case "doc":
        //         navigate({
        //             search: (old) => ({
        //                 ...old,
        //                 type: "doc",
        //                 id: item.id,
        //             }),
        //         });
        //         // setEditing(item);
        //         break;
        //     case "url":
        //         navigate({
        //             search: (old) => ({
        //                 ...old,
        //                 type: "url",
        //                 url: item.url,
        //             }),
        //         });
        //         // setEditing(item);
        //         break;
        //     default:
        //         // alert("unknown what to do with");
        //         return;
        // }
      };
      const treeNodeProps = {
        key: item.key,
        title: (
          <div className="" onClick={onClick}>
            {item.type === "folder" ? (
              <span
                className={[
                  viewingKeys.includes(item.key) ? "font-semibold" : "",
                  searchFoundKeys.includes(item.key) ? "text-red-700" : "",
                  // item.title?.toLowerCase().includes(searchText?.toLowerCase())
                  //   ? "text-red-400"
                  //   : "",
                ].join(" ")}
              >
                {item.title?.length ? item.title : "no-title"}
              </span>
            ) : (
              <Link
                search={{
                  type: item.type,
                  url: item.url,
                  id: item.id,
                }}
                // onClick={onClick}
                title={item.url}
              >
                <span
                  className={
                    viewingKeys.includes(item.key) ? "font-semibold" : ""
                  }
                >
                  <Highlighter
                    highlightClassName="text-red-500 font-bold"
                    searchWords={item.title?.length ? [searchText] : ""}
                    autoEscape={true}
                    textToHighlight={
                      item.title?.length ? item.title : "no-title"
                    }
                  />
                </span>
              </Link>
            )}
            <div className="absolute top-0 right-0 flex flex-row space-x-1">
              {item.url?.length ? (
                <div className="is-menu-button">
                  <a
                    href={item.url}
                    target="_blank"
                    className="inline-flex w-full justify-center rounded-md px-1 text-sm font-medium hover:bg-black hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                  >
                    <ExternalLinkIcon
                      className="h-5 w-5 text-violet-200 hover:text-violet-100"
                      aria-hidden="true"
                    />
                  </a>
                </div>
              ) : null}
              <AddToSidebar
                item={item}
                onNewFolder={() =>
                  setFolderInfo({
                    newParentKey: item.key,
                    item: {
                      type: "folder",
                      title: "",
                      children: [],
                    },
                  })
                }
                onAdd={handleAddItem}
              />
              <ItemDropdown
                onDelete={handleDeleteItem}
                deleteText={"Remove from Collection"}
                setEditing={setEditing}
                setFolderInfo={setFolderInfo}
                item={item}
                type={item.type}
                onNewFolder={
                  item.type === "folder"
                    ? () =>
                        setFolderInfo({
                          newParentKey: item.key,
                          item: {
                            type: "folder",
                            title: "",
                            children: [],
                          },
                        })
                    : undefined
                }
              />
            </div>
          </div>
        ),
        icon: icon,
        item: item,
      };
      if (item.children) {
        return <TreeNode {...treeNodeProps}>{loop(item.children)}</TreeNode>;
      }
      return <TreeNode {...treeNodeProps} />;
    });
  console.log("rebuild loop");

  const switcherIcon = (obj) => {
    // console.log("obj:", obj);
    if (obj.item.type === "folder") {
      return false;
    }
    return undefined;

    // if (obj.isLeaf) {
    //     return getSvgIcon(
    //         arrowPath,
    //         { cursor: "pointer", backgroundColor: "white" },
    //         { transform: "rotate(270deg)" }
    //     );
    // }
    // return getSvgIcon(
    //     arrowPath,
    //     { cursor: "pointer", backgroundColor: "white" },
    //     { transform: `rotate(${obj.expanded ? 90 : 0}deg)` }
    // );
  };

  // console.log("expandedKeys:", expandedKeys);
  return (
    <div
      className={cx(
        css`
          & .is-collection {
            .is-menu,
            .is-menu-button {
              opacity: 0.05;
            }
          }
          & .is-collection:hover {
            background: #efefef;
            .is-menu,
            .is-menu-button {
              opacity: 1;
            }
          }
          & .is-collection:focus-within {
            background: #efefef;
            .is-menu,
            .is-menu-button {
              opacity: 1;
            }
          }
          & .rc-tree-title {
            width: 220px;
            .is-menu,
            .is-menu-button {
              opacity: 0.05;
            }
          }
          & .rc-tree-title:hover {
            .is-menu,
            .is-menu-button {
              opacity: 1;
            }
          }
          & .rc-tree-title:focus-within {
            .is-menu,
            .is-menu-button {
              opacity: 1;
            }
          }
          & .rc-tree-treenode {
            position: relative;
            &:hover,
            :focus-within {
              background: #efefef;
            }
          }
          & .rc-tree .rc-tree-treenode .rc-tree-node-content-wrapper {
            position: initial;
          }
        `
        // "mb-2"
      )}
    >
      {/* {showContextMenu ? (
                <ClickAwayListener onClickAway={() => setShowContextMenu(null)}>
                    <div
                        className={`absolute z-50 top-[${showContextMenu.pageX}px] left-[${showContextMenu.pageY}px] w-auto rounded-2xl bg-white p-6 text-left align-middle shadow-xl`}
                    >
                        TestingContext
                    </div>
                </ClickAwayListener>
            ) : null} */}
      <CollectionFolderDialog
        item={folderInfo?.item}
        isNew={folderInfo?.newParentKey ? true : false}
        onComplete={(folderItem) => {
          setFolderInfo(null);
          if (!folderItem) {
            return;
          }

          // "folderInfo" is still correct though

          const loop = (data, key, callback) => {
            data.forEach((item, index, arr) => {
              if (item.key === key) {
                callback(item, index, arr);
                return;
              }
              if (item.children) {
                loop(item.children, key, callback);
              }
            });
          };
          const data = [...gData];

          if (folderInfo.newParentKey) {
            // insert inside the passed-in item
            // alert("insert inside");
            // modify
            if (folderInfo.newParentKey === true) {
              // adding to collection
              data.unshift({
                key: uuidv4(),
                ...folderItem,
              });
            } else {
              loop(data, folderInfo.newParentKey, (item, index, arr) => {
                item.children = item.children || [];
                item.children.unshift({
                  key: uuidv4(),
                  ...folderItem,
                });
                // arr[index] = folderItem;
              });
            }
          } else {
            alert(
              "done editing (this actually uses the Edit Item dialog, NOT this..."
            );
          }
          updateCollection.mutateAsync({ id, items: data });
          setGData(data);
        }}
      />
      <CollectionItemDialog
        item={editing}
        onComplete={(updatedItem) => {
          setEditing(null);
          if (!updatedItem) {
            return;
          }

          const loop = (data, key, callback) => {
            data.forEach((item, index, arr) => {
              if (item.key === key) {
                callback(item, index, arr);
                return;
              }
              if (item.children) {
                loop(item.children, key, callback);
              }
            });
          };
          const data = [...gData];

          // modify
          loop(data, updatedItem.key, (item, index, arr) => {
            arr[index] = updatedItem;
          });
          updateCollection.mutateAsync({ id, items: data });
          setGData(data);
        }}
      />
      <div className="is-collection whitespace-nowrap relative">
        <span
          onClick={() => setShowCollection(!showCollection)}
          className="cursor-pointer"
        >
          {showCollection ? (
            <ChevronDownIcon className="w-5 h-5  inline-block" />
          ) : (
            <ChevronRightIcon className="w-5 h-5  inline-block" />
          )}
        </span>
        <CollectionIcon
          className="h-4 w-4 inline"
          onClick={() => setShowCollection(!showCollection)}
        />{" "}
        {collection ? (
          <>
            <span
              onClick={() => setShowCollection(!showCollection)}
              onDoubleClick={() => handleEditCollection(collection)}
              className={viewingKeys.length ? "font-semibold" : ""}
            >
              {collection.name?.length ? collection.name : "no-name"}
            </span>
            <PermissionIcon obj={collection} />
            <div className="absolute top-0 right-0 flex flex-row space-x-1">
              <AddToSidebar
                item={collection}
                type="collection"
                onNewFolder={() =>
                  setFolderInfo({
                    newParentKey: true,
                    item: {
                      type: "folder",
                      title: "",
                      children: [],
                    },
                  })
                }
                onAdd={handleAddItem}
              />
              <ItemDropdown
                onDelete={onRemove}
                deleteText={"Remove from Sidebar"}
                setEditing={setEditing}
                item={collection}
                type="collection"
                onEdit={() => handleEditCollection(collection)}
                onNewFolder={() =>
                  setFolderInfo({
                    newParentKey: true,
                    item: {
                      type: "folder",
                      title: "",
                      children: [],
                    },
                  })
                }
              />
            </div>
          </>
        ) : (
          "Loading Collection"
        )}
      </div>
      <div className={`pl-4 ${showCollection ? "" : "hidden"}`}>
        <Tree
          // key={uuidv4()} // this is necessary to get expansion of folders working correctly on load?
          showLine
          draggable
          selectable={false}
          allowDrop={allowDrop}
          expandAction="click"
          expandedKeys={expandedKeys ?? []}
          autoExpandParent={false}
          onExpand={(keys, rest) => {
            // console.log("onExpand:", keys, rest);
            setDefaultKeysOpenFromLocalStorage(keys);
            setExpandedKeys(keys);
          }}
          // checkable
          // onExpand={this.onExpand}
          // expandedKeys={this.state.expandedKeys}
          // autoExpandParent={this.state.autoExpandParent}
          // onCheck={this.onCheck}
          // checkedKeys={this.state.checkedKeys}
          // onSelect={this.onSelect}
          // selectedKeys={this.state.selectedKeys}
          // onRightClick={({ node, event }) => {
          //     console.log("right click", { node, event });
          //     alert("right click");
          // }}
          // onRightClick={handleContextMenu}
          dropIndicatorRender={DropIndicator}
          onDragStart={onDragStart}
          onDragEnter={onDragEnter}
          onDrop={onDrop}
          // switcherIcon={switcherIcon}
        >
          {loop(gData)}
        </Tree>
      </div>
    </div>
  );
};

const AddToSidebar = ({ item, type, onAdd, onNewFolder }) => {
  const currentlyViewing = useAppStore((s) => s.currentlyViewing);

  const search = useSearch();
  // switch (search.type) {
  //     case "doc":
  //     case "file":
  //     case "url":
  //         break;
  //     default:
  //         return null;
  // }

  const handleNewItemFromCurrent = () => {
    onAdd(type === "collection" ? true : item.key, {
      type: search.type,
      id: search.id,
      url: search.url,
      title: currentlyViewing?.title || "Unknown",
    });
    // const loop = (data, key, callback) => {
    //     data.forEach((item, index, arr) => {
    //         if (item.key === key) {
    //             callback(item, index, arr);
    //             return;
    //         }
    //         if (item.children) {
    //             loop(item.children, key, callback);
    //         }
    //     });
    // };
    // const data = [...gData];
  };

  const handleAddType = (info) => () => {
    onAdd(type === "collection" ? true : item.key, info, true);
  };

  if (item.type !== "folder" && type !== "collection") {
    return null;
  }

  return (
    <div className="is-menu text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div style={{}}>
          <Menu.Button className="inline-flex w-full justify-center rounded-md px-1 text-sm font-medium hover:bg-black hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <PlusSmIcon
              className="h-5 w-5 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-50 right-0 mt-0 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              {currentlyViewing ? (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={handleNewItemFromCurrent}
                      className={`${
                        active ? "bg-purple-500 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      Current: {currentlyViewing.title}
                    </button>
                  )}
                </Menu.Item>
              ) : null}
              {/* <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={handleAddType({
                      type: "url",
                      url: "",
                      title: "",
                    })}
                    className={`${
                      active ? "bg-purple-500 text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    New URL
                  </button>
                )}
              </Menu.Item> */}
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={onNewFolder}
                    className={`${
                      active ? "bg-purple-500 text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    New Folder
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
  // return (
  //     <div className="is-menu absolute top-0 right-6 text-right">
  //         <div className="relative inline-block text-left">
  //             <button
  //                 onClick={handleNewItem}
  //                 className="justify-center rounded-md px-1 text-sm font-medium hover:bg-black hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
  //             >
  //                 <PlusSmIcon
  //                     className="h-5 w-5 text-violet-200 hover:text-violet-100"
  //                     aria-hidden="true"
  //                 />
  //             </button>
  //         </div>
  //     </div>
  // );
};

// const isMatchOrHasChildMatch = (search, item) => {
//   // handle top-level collection
//   // - and the collection.items

//   (search.id && search.id === item.id) ||
//     (search.url && search.url === item.url);
// };

function ItemDropdown({
  onDelete,
  deleteText,
  onEdit,
  onNewFolder = () => {},
  setEditing,
  item,
  type,
}) {
  return (
    <div className="is-menu text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div style={{}}>
          <Menu.Button className="inline-flex w-full justify-center rounded-md px-1 text-sm font-medium hover:bg-black hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <ChevronDownIcon
              className="h-5 w-5 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-50 right-0 mt-0 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => (onEdit ? onEdit(item) : setEditing(item))}
                    className={`${
                      active ? "bg-purple-500 text-white" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    Edit
                  </button>
                )}
              </Menu.Item>
              {/* {type === "collection" || type === "folder" ? (
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            onClick={onNewFolder}
                                            className={`${
                                                active
                                                    ? "bg-purple-500 text-white"
                                                    : "text-gray-900"
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        >
                                            New Folder
                                        </button>
                                    )}
                                </Menu.Item>
                            ) : null} */}
              {/* <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${
                                            active
                                                ? "bg-purple-500 text-white"
                                                : "text-gray-900"
                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                        Duplicate
                                    </button>
                                )}
                            </Menu.Item> */}
            </div>
            {/* <div className="px-1 py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${
                                            active
                                                ? "bg-purple-500 text-white"
                                                : "text-gray-900"
                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                        Archive
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={`${
                                            active
                                                ? "bg-purple-500 text-white"
                                                : "text-gray-900"
                                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                    >
                                        Move
                                    </button>
                                )}
                            </Menu.Item>
                        </div> */}
            {onDelete ? (
              <div className="px-1 py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => onDelete(item)}
                      className={`${
                        active ? "bg-red-500 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      {deleteText ?? "Delete"}
                    </button>
                  )}
                </Menu.Item>
              </div>
            ) : null}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

function DropIndicator({
  dropPosition,
  dropLevelOffset,
  indent,
}: {
  dropPosition: -1 | 0 | 1;
  dropLevelOffset: number;
  indent: number;
}) {
  const style: React.CSSProperties = {
    pointerEvents: "none",
    position: "absolute",
    right: 0,
    backgroundColor: "red",
    height: 4,
  };
  switch (dropPosition) {
    case -1:
      style.top = 0;
      style.left = -dropLevelOffset * indent;
      break;
    case 1:
      style.bottom = 0;
      style.left = -dropLevelOffset * indent;
      break;
    case 0:
      style.bottom = 0;
      style.left = indent;
      break;
  }
  return <div style={style} />;
}

const isDescendant = function (parents, child) {
  let node = child.parentNode;
  while (node) {
    for (let i = 0; i < parents.length; i++) {
      if (node === parents[i]) {
        return true;
      }
    }

    // Traverse up to the parent
    node = node.parentNode;
  }

  // Go up until the root but couldn't find the `parent`
  return false;
};

export const getAllKeys = (data) => {
  const keys = [];
  const loopKeys = (children) => {
    children.map((category) => {
      keys.push(category.key); // or any other property you are using as a key
      if (category.hasChildren) {
        // or you can check the length of children:[]
        loopKeys(category.children);
      }
    });
    return keys;
  };

  loopKeys(data);
  return keys;
};

export default Sidebar;
