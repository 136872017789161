import { useQuery, useMutation } from "react-query";
import { supabase } from "../lib/api";

const getFileLink = async ({ path, timeout = 3600 }) => {
  const expires = new Date(Date.now() + timeout * 1000);

  const { data, error } = await supabase.storage
    .from("private-files")
    .createSignedUrl(path, timeout);

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error("File not found");
  }

  // console.log("Signed data:", data);

  const { signedUrl } = data;

  return { expires, signedUrl };
};

export const useFileLinkMutation = () => {
  return useMutation(getFileLink);
};

export const useFileLinkQuery = ({ path, timeout, enabled = true }) => {
  return useQuery(
    ["files", "link", { path, timeout }],
    () => getFileLink({ path, timeout }),
    {
      enabled: enabled && !!path?.length,
    }
  );
};
